const apiCalls = {
  loginUser: "auth/login",
  logoutUser: "auth/logout",
  loginChangePassword: "auth/loginChangePassword",
  MenuList: "menus",
  Settings: "settings",
  LocationImagePath: "uploads?uploadWhileCreate=true&uploadPath=location",
  ProfileImagePath: "uploads?uploadWhileCreate=true&uploadPath=employee",
  dashboard: "dashboard/counts",
  Uploads: "bulkuploadStatus",
  employees: "employees",
  myDrives: "myDrives",
  users: "users",
  homes: "homes",
  files: "files",
  folders: "folders",
  sharedFiles: "sharedFiles",
  shareChat:'chats/shareChatLink',
  folderUplode:"folders/uploadFolder",
  chatWithAi : 'files/chatWithAI',
  messages : 'messages',
  fileRead: 'files/fileRead',
  shareChatEmployees : 'chats/shareChat',
  summary : 'files/summarize',
  askQuestion : 'files/askQuestion',
  chats : 'chats',
  sharedChats : 'chats/shareChat',
  sharedChatUpdate: 'chats/sharedChatUpdate',
  verifyEmail : 'employees/verify-email',
  compareFiles: 'files/compareFiles'

};

// {"loginUser":"auth/login","logoutUser":"auth/logout","loginChangePassword":"auth/loginChangePassword","MenuList":"menus","Settings":"settings","LocationImagePath":"uploads?uploadWhileCreate=true&uploadPath=location","ProfileImagePath":"uploads?uploadWhileCreate=true&uploadPath=employee","dashboard":"dashboard/counts","employee":"employees","employees":"employees","Uploads":"bulkuploadStatus"}

export default apiCalls;
