import React, { useCallback, useState, useRef ,useEffect} from 'react';
import { useDropzone } from 'react-dropzone';
import fetchMethodRequest from '../../../config/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import configMessages from '../../../config/configMessages';
import Loader from './Loader';
import JSZip from 'jszip';
import config from '../../../config/config';
import { marked } from 'marked';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import  {BeatLoader} from 'react-spinners'
import { FileAskQuestion } from './FileAskQuestion';

const Dropzone = (props) => {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({ totalFiles: 0, uploadedFiles: 0 });
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const scrollContainerRef = useRef(null);
    const foldersEndRef = useRef(null);
    let isThrottled = useRef(false)

    const onDrop = useCallback((acceptedFiles) => {
        const hasFolder = acceptedFiles.some(file => file.path && file.path.includes('/'));
        const initialFiles = acceptedFiles.map(file => ({
            name: file.name,
            progress: 0,
            status: 'uploading'
        }));
        setFiles(initialFiles);
        setIsLoaderVisible(true);
        setUploadProgress({ totalFiles: acceptedFiles.length, uploadedFiles: 0 });

        if (hasFolder) {
            handleFolderDrop(acceptedFiles);
        } else {
            handleFilesUpload(acceptedFiles);
        }
    }, []);

    useEffect(()=>{
        if(props.onRef){
            props.onRef({
                setFiles,
                setIsLoaderVisible,
                setUploadProgress,
                updateFileStatus
            })
        }
    },[]);  

    const handleFilesUpload = (files) => {
        files.forEach((file, index) => {
            const formData = new FormData();
            formData.append('file', file);
            let url = '';
            if (props?.assingValueToForm?.path && props?.assingValueToForm?.parentFolderId) {
                url = `files?path=${props?.assingValueToForm?.path}&folderId=${props?.assingValueToForm?.parentFolderId}&type=files`;
            } else {
                url = 'files';
            }

            fetchMethodRequest("POST", url, formData, 'upload', true, props.assingValueToForm)
                .then(async (response) => {
                    if (response && response.respMessage) {
                        props.getDataFromServer();
                        showToasterMessage(response.respMessage, 'success');
                        updateFileStatus(index, 100, 'success');
                        setIsDragging(false)
                    } else if (response && response.errorMessage) {
                        props.getDataFromServer();
                        showToasterMessage(response.errorMessage, 'error');
                        updateFileStatus(index, 100, 'error');
                        setIsDragging(false)
                    }
                }).catch(() => {
                    updateFileStatus(index, 100, 'error');
                });
        });
    };

    const handleFolderDrop = useCallback(async (files) => {
        try {
            const zip = new JSZip();
            const filePromises = files.map((file) => {
                const relativePath = file.path.replace(/^\//, '');
                return file.arrayBuffer()
                    .then(fileContent => zip.file(relativePath, fileContent))
                    .catch(error => {
                        console.error('Error processing file:', error);
                        throw error;
                    });
            });

            await Promise.all(filePromises);
            const blob = await zip.generateAsync({ type: "blob" });
            const formData = new FormData();
            formData.append('file', blob, 'uploaded-folder.zip');

            let url = '';
            if (props?.assingValueToForm?.path && props?.assingValueToForm?.parentFolderId) {
                url = `folders/uploadFolder?path=${props?.assingValueToForm?.path}&folderId=${props?.assingValueToForm?.parentFolderId}&type=files`;
            } else {
                url = 'folders/uploadFolder';
            }

            fetchMethodRequest('POST', url, formData)
                .then(async (response) => {
                    if (response && response.respMessage) {
                        props.getDataFromServer();
                        setIsDragging(false);
                        showToasterMessage(response.respMessage, 'success');
                        files.forEach((file, index) => updateFileStatus(index, 100, 'success'));
                    } else if (response && response.errorMessage) {
                        props.getDataFromServer();
                        setIsDragging(false);
                        showToasterMessage(response.errorMessage, 'error');
                        files.forEach((file, index) => updateFileStatus(index, 100, 'error'));
                    }
                })
                .catch((err) => {
                    console.error('Fetch error:', err);
                    showToasterMessage('Upload failed', 'error');
                    files.forEach((file, index) => updateFileStatus(index, 100, 'error'));
                });
        } catch (error) {
            console.error('Error uploading folder:', error);
            showToasterMessage('Upload failed', 'error');
        }
    }, [props.assingValueToForm, props.getDataFromServer]);

    const updateFileStatus = (index, progress, status) => {
        let noOfFiles = 0;
        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].progress = progress;
            updatedFiles[index].status = status;
            noOfFiles = updatedFiles.length
            return updatedFiles;
        });
        setUploadProgress((prevProgress) => ({
            ...prevProgress,
            uploadedFiles: prevProgress.uploadedFiles + 1,
        }));
        if(noOfFiles === index +1) {
            setTimeout(() => {
               setIsLoaderVisible(false) 
            }, config.progressBarClosingTime);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        onDragEnter: () => setIsDragging(true),
        onDragLeave: () => setIsDragging(false),
        webkitdirectory: true,
    });

    const renderDataSection = () => {
           if(props.viewType === 'Grid')
            return (
                    <>
                        {props.folders.length === 0 && props.files.length === 0 && props.contentSearchedFiles?.length == 0 && props.isLoading() ? (
                            <h3 className='text-center' style={{ textAlign: 'center' }}>No Data Found</h3>
                        ) : (
                            <>
                                {props.folders.length > 0 && (
                                    <>
                                        <h3 className='d-flex p-1' style={{ marginLeft: '3px' }}>Folders</h3>
                                        {props.renderData(props.folders)}
                                        <div ref={foldersEndRef} />
                                    </>
                                )}
                                {props.files.length > 0 && (
                                    <>
                                        <h3 className='d-flex p-1 mt-3' style={{ marginLeft: '3px' }}>Files</h3>
                                        {props.renderData(props.files)}
                                    </>
                                )}

                                {(props.contentSearchedFiles?.length > 0 && props.getContentFiles) && (
                                    <>
                                        <h3 className='d-flex p-1 mt-3' style={{ marginLeft: '3px' }}>Content Searched Files</h3>
                                        {props.getContentFiles(props.contentSearchedFiles , 'contentFiles')}
                                    </>
                                )}
                            </>
                        )}
                    </>
                );
            else
            return (
                <>{props.renderData()}</>
            )
    };

    const renderDropzone = () => {
        return (
            <div {...getRootProps()} style={{ ...dropzoneStyle, ...(isDragActive ? activeStyle : {}),height : props.contentSearchedFiles?.length > 0 ? ''  : '70vh'}}>
                <input {...getInputProps()} ref={fileInputRef} multiple />
                <div ref={scrollContainerRef} onScroll={handleScroll} className={'whbgrcolor row ml-lg-0 px-2  pb-3 '+(props.viewType === 'Grid' ? 'dropzone-scroll' : '')}>
                        {renderDataSection()}       
                    </div>
                <div className='water-mark'></div>
                {isDragActive && <div className="upload-icon-container">
                    <FontAwesomeIcon
                        icon={isDragActive}
                        size="4x"
                        color={isDragActive ? "rgb(13, 110, 253)" : ''}
                        className={`upload-icon ${isDragActive ? 'animate-cloud' : ''}`}
                    />
                </div>}
                {isDragActive && (
                    <div className="text-icon-container">
                        <div className="dropLocationStyle">
                            <p style={dropTextStyle}>
                                {props.assingValueToForm?.path
                                    ? configMessages.dragExtenction  + `${props.assingValueToForm.path.split('/').pop()}`
                                    : configMessages.defaultDragMessage}
                            </p>
                        </div>
                    </div>
                )}
                {isLoaderVisible && (
                    <Loader
                        files={files}
                        totalFiles={uploadProgress.totalFiles}
                        uploadedFiles={uploadProgress.uploadedFiles}
                        uploadedFileNames={uploadProgress.uploadedFileNames}
                        onClose={() => setIsLoaderVisible(false)}
                    />
                )}
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div style={{padding : '10px'}}>
                <div className={'whbgrcolor row ml-lg-0 px-2  pb-3 '+(props.viewType === 'Grid' ? 'dropzone-scroll' : '') }>
                {renderDataSection()}
                </div>
            </div>
        );
    };


    const handleScroll = (event) => {
        event.preventDefault()
        if (isThrottled.current) {
            return;
        }
        const scrollContainer = scrollContainerRef.current;
        const foldersEnd = foldersEndRef.current;

        if (scrollContainer && foldersEnd) {
            const { scrollTop, clientHeight,scrollHeight} = scrollContainer;
            const foldersEndPosition = foldersEnd.offsetTop;

            // Check if the bottom of the visible area has reached the end of the folders
            if ((scrollTop + clientHeight >= foldersEndPosition && props.folderLoading) || ((scrollTop + clientHeight + 1) >= (scrollHeight))) {
                if (props?.allUsersData?.length < props?.totalRecords) {
                    let filter = props?.filterCriteria;
                    filter.page = props.currentPageRef.current + 1;
                    props?.getDataFromServer(filter, 'onScroll')
                    props?.setFilterCriteria(filter);
                    isThrottled.current = true;
                    setTimeout(() => {
                      isThrottled.current = false;
                    }, 300);
                }   
            }
        }
    };

    return (
        <div className='row pr-0 gx-1'>
            <div className={'px-0' + (props.openSidePanel ? ' col-8' : ' col-12')}>
                {(props.displayName === 'Home' || (props.type === 'SharedFiles' && props.query && props.query.size && props.query.size > 0 && props.folderPermission && props.folderPermission === 'Edit'))? renderDropzone() : renderContent()}
            </div>
            {props.openSidePanel && <div className='col-4' style={{...dropzoneStyle,height : props.contentSearchedFiles?.length > 0 ? '' : '70vh'}}>
                <div className='whbgrcolor summary-container overflow-hidden'>
                    <BeatLoader color='#7a70ba' size={20} cssOverride={{...loaderStyle}} loading={props.isSidePanelLoading}/>
                    <div>
                        <div className='d-flex justify-content-between align-items-center m-2 summary-file-name'>
                            <div className='textElipsess'>{props?.sidePanelDetails?.fileName}</div>
                            <div className='px-2 getcursor'>
                                <FontAwesomeIcon
                                icon={faTimes}
                                className='pl-1'
                                data-toggle="tool-tip" title="Close"
                                onClick={props.closeSidePanel}
                                style={{
                                color :'#7a70ba',
                                fontSize : '1rem'
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='pb-5 dropzone-scroll' >
                    {
                    props?.dataType === 'summary' ? <div className='d-flex justify-content-start'>
                        <div className='message bot summary-message'>
                        <div dangerouslySetInnerHTML={{ __html: props?.sidePanelDetails?.answer ? marked(props?.sidePanelDetails?.answer) : ''}} ></div>
                        </div>
                    </div> :
                    <FileAskQuestion fileDetails={props.sidePanelDetails} />
                    }
                    </div>
                </div>
            </div>}
        </div>
    );
}; 

const dropzoneStyle = {
    borderRadius: '5px',
    padding: '10px',
    textAlign: 'center',
    height: '70vh',
    position: 'relative',
};

const loaderStyle = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
};


const activeStyle = {
    backgroundColor: 'rgba(13, 110, 253, 0.1)',
};


const dropTextStyle = {
    color: '#ffff',
    fontWeight: 'bold',
};

export default Dropzone;
