import { Badge, Col, Row, Card, CardBody, CardHeader, Collapse, ButtonGroup, ButtonToolbar } from 'reactstrap';
import moment from 'moment';
import { Link, NavLink, Navigate } from 'react-router-dom';
import '../../../scss/dashboardStyles.css';
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import FilterOperations from './FilterOperations';
import { Paginator } from 'primereact/paginator';
import { CSVLink } from "react-csv";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import Dropzone from './DropZone';
//Modals
import ShowHideColumnsModal from '../CommonModals/ShowHideColumnsModal';
import FormModal from '../../Form/FormModal';
import DeleteRowModal from '../CommonModals/DeleteRowModal';
import ShareModal from '../CommonModals/ShareFilesModal';
import ViewModal from '../CommonModals/viewModal';
import BulkUploadModal from '../CommonModals/BulkUploadModal';
import ConfirmationModal from '../CommonModals/ConfirmationModal';
import SessionExpiryModal from '../CommonModals/SessionexpiryModal';
import PreviewModal from '../CommonModals/PreviewModal';
import SendEmailModal from '../CommonModals/SendEmailModal';
import MoveFilesModal from '../CommonModals/MoveFilesModal';
import DeleteFilterModal from '../CommonModals/DeleteFilterModal';
import ExportToCsvModal from '../CommonModals/ExportToCsvModal';
// fetch method for server call
import fetchMethodRequest from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import configMessages from '../../../config/configMessages';
import bulkSampleFiles from '../../../config/configSampleFiles'
import apiCall from '../../../config/apiCalls';
//Render Date picker
import RenderIntervalDatePickerField from '../../../shared/components/form/IntervalDatePicker';
import MultiFileUplode from '../CommonModals/MultiFileUplode';
// Toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Date Formate
import dateFormats from '../../UI/FormatDate/formatDate';
//Loader
import Loader from '../../App/Loader';
//store
import store from '../../App/store'
import validate from '../../Validations/validate'
import { reduxForm, } from 'redux-form'
import { connect } from 'react-redux'
import crypto from "crypto-js";
import PaginatorComponent from './PaginatorComponent';
import { element } from 'prop-types';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCog, faPrint, faGripHorizontal, faList } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'primereact/button';
import { formatBytes, formatPhoneNumber, formatPropType } from '../../../utils/utils';
import FunnelFilter from './FunnelFilter';
import { Controller, useForm } from 'react-hook-form';
import DoFileUpload from '../../Form/Fields/DoFileUpload';
library.add(faCog, faPrint, faGripHorizontal, faList);
import { faClipboardList} from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { marked } from 'marked';


// tooltip styles
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: '500',
    margin: 0,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
}))(Tooltip);

const AnotherLightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: '500',
    margin: 0,
  },
}))(Tooltip);

function DataTables(props) {

  const [allUsersData, setAllUsersData] = useState([]);
  const [deleteRowDataId, setDeleteRowDataId] = useState({});
  const [tableFields, setTableFields] = useState(props.getTableFields());
  const [originalTableFields, setOriginalTableFields] = useState(props.getTableFields());
  const [tablefieldsToShow, setTablefieldsToShow] = useState(props.getTableFields());
  const [isOpenShowHideColumnsModal, setIsOpenShowHideColumnsModal] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [sortified, setSortified] = useState(false);
  const [direction, setDirection] = useState('asc');
  const [filterCriteria, setFilterCriteria] = useState({ limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsId, setSelectedRowsId] = useState();
  const [totalRecords, setTotalRecords] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedScreenPermission, setSelectedScreenPermission] = useState('');
  const [loginRole, isLoginRole] = useState('Admin');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [apiUrl, setApiUrl] = useState(props.apiUrl);
  const [totalRecordsLength, setTotalRecordsLength] = useState(0);
  const [tabType, setTabType] = useState(props.tabType);
  const [mobileListFields, setMobileListFields] = useState([]);
  const [dateFormat, setDateFormat] = useState();
  const [selectschool, setSelectschool] = useState();
  const [roleType, setRoleType] = useState();
  const [openId, setOpenId] = useState(1);
  const [redirecToLogin, setRedirecToLogin] = useState(false);
  const [blockFamily, setBlockFamily] = useState(false);
  const [selectActions, setSelectActions] = useState();
  const [viewType, setViewType] = useState(props?.viewType?.viewType ? props?.viewType?.viewType : 'list');
  const [actions, setActions] = useState();
  const [displayBreadCrumbField, setDisplayBreadCrumbField] = useState();
  const [displayBreadCrumbValue, setDisplayBreadCrumbValue] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [globalSearch, setGlobalSearch] = useState();
  const [showMultiSelectDropDown, setShowMultiSelectDropDown] = useState(false);
  const [multiSelectTypes, setMultiSelectTypes] = useState();
  const [actionsTypes, setActionTypes] = useState(props.actionsTypes);
  const [userStatus, setUserStatus] = useState();
  const [selectedAutoCompleteValue, setSelectedAutoCompleteValue] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [confirmModalText, setConfirmModalText] = useState();
  const [confirmType, setConfirmType] = useState();
  const [emailTemplateData, setEmailTemplateData] = useState();
  const [redirectToRoute, setRedirectToRoute] = useState(false);
  const [searchInDateRangeField, setSearchInDateRangeField] = useState(props.searchInDateRangeField ? props.searchInDateRangeField : "created");
  const [fieldMap, setFieldMap] = useState({});
  const [formType, setFormType] = useState();
  const [page, setPage] = useState(1);
  const [sortCount, setSortCount] = useState();
  const [sortOrder,setSortOrder] = useState();
  const [sortField , setsortField] = useState()
  const [confirmText, setConfirmText] = useState();
  const [leaveStatus, setLeaveStatus] = useState();

  const [isOpenRolesModal, setIsOpenRolesModal] = useState(false);
  const [isOpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openBulkUploadModal, setOpenBulkUploadModal] = useState(false);
  const [openExportToCsvModal, setOpenExportToCsvModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [sessionExpiryModal, setSessionExpiryModal] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [isOpenProductsModal, setIsOpenProductsModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSharedModal, setOpenSharedModal] = useState(false);
  const [openMoveFolder, setOpenMoveFolder] = useState(false);
  const [openUplodModal,setOpenUplodeModal]=useState(false)
  const [displayViewOfForm, setDisplayViewOfForm] = useState(props.displayViewOfForm);
  const [openUserPasswordResetModal, setOpenUserPasswordResetModal] = useState(false);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [tableRowData, setTableRowData] = useState({});
  const [listPreferences, setListPreferences] = useState("");
  // const [saveCriteria,setSaveCriteria] = useState({});
  const [saveCriteria, setSaveCriteria] = useState({ criteria: [] });
  const [savedFiltCriteria, setSavedFiltCriteria] = useState({});
  const [sortFieldForFunnel, setSortFieldForFunnel] = useState("");
  const [selectedId, setSelectedId] = useState(props.selectedId);
  const [dataLoadType, setDataLoadType] = useState(props?.dataLoadType?.dataLoadType ? props?.dataLoadType?.dataLoadType : 'folders');
  const [customFormData, setCustomFormData] = useState({});
  const [deleteType, setDeleteType] = useState()
  let formModalRef = useRef(null);
  const csvLinkRef = useRef();
  const [sharedFilesResponse, setSharedFilesResponse] = useState([])
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [fileUrl, setFileUrl] = useState()
  const [moveFoldersId,setMoveFolderId]=useState()
  const [draggedItem, setDraggedItem] = useState(null);
  let dropZoneRef = useRef(null);
  const {control , formState , reset} = useForm()
  const {errors} = formState;
  const [noData, setNoData] = useState(false)

  const [sidePanelDetails, setSidePanelDetails] = useState(null)
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [isSidePanelLoading, setIsSidePanelLoading] = useState(false);
  const [sidePanelType , setSidePanelType] = useState('');

  const [activeOptionId , setActionOptionId] = useState('')
  const [openUpward, setOpenUpward] = useState('');
  const menuRef = useRef(null);
  
  const isThrottled = useRef(false);
  let currentPageRef = useRef(0);
  const tableRef = useRef(null);
  let numberOfFoldersRef = useRef(0);
  const [previousData , setPreviousData] = useState([]);
  const [folderLoading , setFolderLoading] = useState(true);
  


  const[serverResponse,setServerResponse] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);


  useEffect(() => {
    setTimeout(() => {
      readData();
    }, 100);
    getFieldTypes();


    updateColumnsOrder(null);
}, []);

  const onVirtualScroll = (event) => {
    event.preventDefault()
    if (isThrottled.current) {
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const lastFolderRow = tableRef.current.querySelector('.last-folder');
    if (lastFolderRow || ((scrollTop + clientHeight + 1) >= (scrollHeight))) {
      const lastFolderPosition = lastFolderRow ? lastFolderRow.getBoundingClientRect().top : ''; // Get the top position of the last folder
      const tableBottomPosition = tableRef.current.getBoundingClientRect().bottom; // Get the bottom position of the table
      // Check if the user has scrolled past the last folder
      if (((lastFolderPosition <= tableBottomPosition) && folderLoading) || ((scrollTop + clientHeight + 1) >= (scrollHeight))) {
        if (allUsersData.length < totalRecords) {
          let filter = filterCriteria;
          filter.page = currentPageRef.current + 1;
          getDataFromServer(filter, 'onScroll')
          setFilterCriteria(filter);
          isThrottled.current = true;
          setTimeout(() => {
            isThrottled.current = false;
          }, 300);
      }     
    }
  }
  };

useEffect(() => {
  const handleClickOutside = (event) => {
    if (activeOptionId && menuRef.current && !menuRef.current.contains(event.target)) {
      setTimeout(() => {
        setActionOptionId('');
      }, 100);
    }
  };

  const adjustMenuPosition = () => {
    if (activeOptionId && menuRef.current) {
      const menuRect = menuRef.current.getBoundingClientRect();
      const menuHeight = menuRef.current.offsetHeight;
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - menuRect.bottom;
      const spaceAbove = menuRect.top;
      if (spaceBelow < menuHeight && spaceAbove > menuHeight) {
        setOpenUpward('menu-top');
      } else {
        setOpenUpward('menu-bottom');
      }
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  adjustMenuPosition();

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [activeOptionId, menuRef]);



  const readData = async () => {
    await getDataFromServer(filterCriteria);
    if (props.addSelectedRecord) {
      openFormModal('', 'add')
    } else if (props.viewSelectedRecord) {
      getActions();
      openFormModal('', 'view')
    } else if (props.editSelectedRecord) {
      openFormModal('', 'edit')
    }
  }


  const getFieldTypes = () => {
    let tableFields = props.getTableFields();
    let fieldMap = {};
    tableFields.forEach((col) => {
      fieldMap[col.field] = col.fieldType ? col.fieldType.toLowerCase() : col.type == "number" ? col.type : null;

    });
    setFieldMap(fieldMap)
  }

  const getActions = async () => {
    let selRows = selectedRows ? [...selectedRows] : [];
    let actionsTypes = props.actionsTypes ? [...props.actionsTypes] : [];
    let result = [];
    if (actionsTypes && actionsTypes.length) {
      for (let i = 0; i < actionsTypes.length; i++) {
        if (actionsTypes[i]['options'] && actionsTypes[i]['options'].length > 0) {
          let options = actionsTypes[i]['options']
          for (let j = 0; j < options.length; j++) {
            if (options[j]['show'] === true) {
              if (selRows && selRows.length >= 2 && options[j]['multiple'] === true) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              } else if (selRows && selRows.length === 1) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              } else if (selRows && selRows.length === 0) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              }
            }
          }
        }
      }
    }
    setActionTypes(result);
  }

  const handleClickOutside = (event) => {
    if (
      closeMultiSelectDropdown.current &&
      !closeMultiSelectDropdown.current.contains(event.target)
    ) {
      setShowMultiSelectDropDown(false);
    }
  };

  const updateDimensions = async () => {
    setWidth(window.innerWidth);
    setHeight(window.height);
  };

  const setEncryptFields = async () => {
    let encryptFields = [];
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      //condition to match fieldType
      if (tablefieldsToShow[i].fieldType == "encryptedField") {
        encryptFields.push(tablefieldsToShow[i]['field']);
      }
    }
    setEncryptFields(encryptFields)
  }
  // Handle Table fields to show in datatable
  const getTableFieldsOrder = () => {
    setTablefieldsToShow([]);
    setIsLoading(true);

    let columns = originalTableFields;
    let tempTableFields = [];
    let newTableFields = [];
    let staticTableFields = [...originalTableFields];
    if (columns) {
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < staticTableFields.length; j++) {
          if (columns[i].field === staticTableFields[j].field && viewType === 'list') {
            let pushItem = staticTableFields[j];
            pushItem.show = columns[i].show;
            pushItem.displayInSettings = columns[i].displayInSettings;
            tempTableFields.push(pushItem);
            break;
          }
          if (columns[i].field === staticTableFields[j].field && viewType === 'grid') {
            let pushItem = staticTableFields[j];
            pushItem.displayInSettings = columns[i].displayInSettings;
            pushItem.mobile = columns[i].mobile;
            tempTableFields.push(pushItem);
            break;
          }
        }
      }
    } else {
      tempTableFields = originalTableFields
    }

    newTableFields = tempTableFields;

    let TableFieldsToShow = [];
    if (newTableFields && newTableFields.length > 0) {
      newTableFields.map(item => {
        if (item.displayInSettings === true && viewType === 'list') {
          TableFieldsToShow.push(item)
        }
        if (item.mobile && viewType === 'grid') {
          TableFieldsToShow.push(item)
        }
      })
    }

    setTablefieldsToShow(props.getTableFields());
    setIsLoading(false);
  }
  const changeCheckIcon = async (index, subIndex, key, value) => {
    let tablefieldsToShow = tablefieldsToShow;
    if (tablefieldsToShow[index]['options'][subIndex]['checkIcon'] === true) {
      let filterCriteria = Object.assign({}, filterCriteria);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj)
        let objValue = obj.value.find(y => y === value)
        let i = obj.value.indexOf(objValue);
        filterCriteria.criteria[objIndex]['value'].splice(i, 1);
        let length = filterCriteria.criteria[objIndex]['value'] ? filterCriteria.criteria[objIndex]['value'].length : 0;
        if (length === 0) {
          filterCriteria.criteria.splice(objIndex, 1)
        }
      }
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      setTablefieldsToShow(tablefieldsToShow);
      setFilterCriteria(filterCriteria);
      await onMultiSelectFilterChange(key, value, 'pop', index)
    } else {
      let filterCriteria = Object.assign({}, filterCriteria);
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      setTablefieldsToShow(tablefieldsToShow);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj);
        await onMultiSelectFilterChange(key, value, 'push', objIndex)
      } else {
        await onMultiSelectFilterChange(key, value, 'push', index)
      }
    }
  }
  const changeViewType = async (viewType) => {
    if (props?.viewType?.setViewType) {
      props?.viewType?.setViewType(viewType);
    }
    setViewType(viewType);
    await getTableFieldsOrder()
  }
  const changeRecords = (type) => {
    if (props?.dataLoadType?.setDataLoadType) {
      props?.dataLoadType?.setDataLoadType(type);
    }
    setDataLoadType(type);
    getDataFromServer(filterCriteria, type);
  }
  // Handle Table fields order of display in DataTable
  const updateColumnsOrder = (currentOrder) => {
    setIsLoading(true);
    let orgTableFields = [...originalTableFields];
    let order = currentOrder ? currentOrder : null;
    let updatedOrder = [];
    let unmatchedTableFields = [...orgTableFields];

    if (order && order.length > 0) {
      order.map(async item => {
        if (item && item.props && item.props.field) {
          orgTableFields.map(col => {
            if (item.props.field === col.field) {
              updatedOrder.push(
                {
                  field: col.field,
                  show: col.show,
                  header: col.header,
                  displayInSettings: col.displayInSettings,
                  mobile: col.mobile,
                  label: col.label ? col.label : col.header
                }
              )
            }
          })
        }
      })

      order.map(async item => {
        if (item && item.props && item.props.field) {
          for (let i = 0; i < unmatchedTableFields.length; i++) {
            if (item.props.field === unmatchedTableFields[i].field) {
              unmatchedTableFields.splice(i, 1)
            }
          }
        }
      })

      if (unmatchedTableFields && unmatchedTableFields.length > 0) {
        unmatchedTableFields.map(col => {
          updatedOrder.push(
            {
              field: col.field,
              show: false,
              header: col.header,
              displayInSettings: col.displayInSettings,
              mobile: false,
              label: col.label ? col.label : col.header
            }
          )
        })
      }
    } else {
      orgTableFields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile,
            label: col.label ? col.label : col.header
          }
        )
      });
    }

    /**@Fetch the listPreferences from loginCredentials */
    getTableFieldsOrder(orgTableFields);
  }

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let dateFormat = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.dateFormat ? storeData.settingsData.settings.dateFormat : "DD-MM-YYYY"
    if (state.dateFormat != dateFormat) {
      return { dateFormat: dateFormat };
    }
    return { dateFormat: dateFormat };

  }

  const getLoginRole = () => {
    let loginData = localStorage.getItem('loginCredentials');
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role) {
        setLoginRole(loginData.role);
      }
    }
  }

  const updateSlNoToData = (itemdata, text) => {
    let modifiedData = [];
    let txt = text + 1;

    itemdata.forEach((item, index) => {
      if (props.flags) {
        let flags = props.flags
        if (item[flags['label']] == flags['value']) {
          modifiedData.push(item);
        }
      }

      else {
        item.Sno = txt;
        txt++;

        modifiedData.push(item);
      }
    });
    return modifiedData;
  }

  const updateDateFormat = (itemdata, dateFormat) => {
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tablefieldsToShow.length; k++) {
        if ("Date" == tablefieldsToShow[k]['fieldType']) {
          itemdata[i][tablefieldsToShow[k]['field']] =
            dateFormats.formatDate(
              itemdata[i][tablefieldsToShow[k]['field']],
              tablefieldsToShow[k]['dateFormat'] ? tablefieldsToShow[k]['dateFormat'] : dateFormat);
        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  const formatFoldersAndFiles = (previousData, responseData) => {
    // Create a single array containing both previous and current data
    const combinedData = [...previousData, ...responseData];

    // Use reduce to segregate folders and files
    const { folders, files } = combinedData.reduce((acc, item) => {
        if (!item.type) {
            acc.folders.push(item); // item is a folder
        } else {
            acc.files.push(item); // item is a file
        }
        return acc;
    }, { folders: [], files: [] });

    // Return the combined result: folders followed by files
    numberOfFoldersRef.current = responseData?.filter( file => !file.type)?.length > 0 ? folders?.length : null 
    return [...folders, ...files];
};


  //get table list data from server with filters if any
  const getDataFromServer = async (filterCriteria, type, options) => {
    isThrottled.current = true;
    let url = getAPIUrl();
    let dateFormat = props.dateFormat
    if (url) {
      let apiUrl;
      setIsLoading(true);
      setSelectedRows('');
      if (type == 'refresh') {
        if (document.getElementById("globalSearch") && document.getElementById('globalSearch').value) {
          document.getElementById('globalSearch').value = '';
        }
        let fieldsToShow = tablefieldsToShow;
        if (fieldsToShow && fieldsToShow.length > 0) {
          for (let i = 0; i < fieldsToShow.length; i++) {
            let options = fieldsToShow[i].options;
            if (options && options.length) {
              for (let j = 0; j < options.length; j++) {
                options[j]['checkIcon'] = false
              }
            }
          }
        }
        setGlobalSearch('');
        setAllUsersData([]);
        setRoleType('');
        setTablefieldsToShow(fieldsToShow);

      }
      if (!filterCriteria || !filterCriteria['criteria']) {
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', softfield: 'created' };
      }
      //KEEP -> PREVIOUS FILTER
      // if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
      //   delete filterCriteria['sortfield'];
      //   delete filterCriteria['direction'];
      // }
      if (type === 'refresh' && filterCriteria && Object.keys(saveCriteria).length <= 0) {
        delete filterCriteria.globalSearch;
        filterCriteria['criteria'] = [];
        filterCriteria['direction'] = 'desc';
        filterCriteria['sortfield'] = 'created';
      }
      if (type === 'refresh' && filterCriteria && Object.keys(saveCriteria).length > 0) {
        delete filterCriteria.globalSearch;
        filterCriteria['criteria'] = saveCriteria.criteria;
        delete filterCriteria['direction'];
        filterCriteria['sortfield'] = 'created';
      }
      if (props.params) {
        let params = props.params
        for (let i in params) {
          if (i) {
            let obj = {}
            obj['key'] = i
            obj['value'] = params[i]
            obj['type'] = 'regexOr'
            filterCriteria['criteria'].push(obj)
          }
        }
      }
      //When a screen has a default filter to be applied
      if (props.defaultCriteria) {
        if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
          let newFilt = filterCriteria['criteria'];
          let dc = Array.from(props.defaultCriteria);
          for (let i = 0; i < newFilt.length; i++) {
            for (let j = 0; j < dc.length; j++) {
              if (newFilt[i].key == dc[j].key) {
                dc.splice(j, 1)
              }
            }
          }
          filterCriteria['criteria'] = filterCriteria['criteria'].concat(dc);
        }
        else {
          filterCriteria['criteria'] = props.defaultCriteria
        }
      }

      if (props.filterQueryParam) {
        if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
          filterCriteria['criteria'].push({ key: props.filterQueryParam?.searchName, value: props.filterQueryParam?.searchId, type: ['eq'] })
        } else {
          filterCriteria['criteria'] = [{ key: props.filterQueryParam?.searchName, value: props.filterQueryParam?.searchId, type: 'eq' }];

        }
        apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
      }

      //applyng filter when a row item is deleted 
      if (filterCriteria == undefined) {
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', softfield: 'created' };
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      if (props.filterExtension) {
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&&${props.filterExtension}`
      }
      else if (props.hasQueryInCall) {
        apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
      }
      else if (type == 'clearFilter' && (Object.keys(filterCriteria).length > 0)) {
        filterCriteria.criteria = filterCriteria.criteria.filter(criterion => criterion.key !== options.field);
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      else {
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }

      if (props.showHomeScreenData) {
        if ((type === 'files' || type === 'folders')) {
          apiUrl += `&type=${type}`
        } else {
          apiUrl
        }
      }

      if(type === 'allFolders') {
        apiUrl += `&type=${type}`
      }

      let limit = filterCriteria.limit;
      let page = filterCriteria.page;
      return fetchMethodRequest('GET', apiUrl)
        .then(async (response) => {
          isThrottled.current = false;
          let apiResponseKey = props.apiResponseKey;
          if (props.showHomeScreenData) {
            if ((type === 'files' || type === 'folders')) {
              apiResponseKey = type
            } else {
              apiResponseKey
            }
          }
          let sessionexpired = await localStorage.getItem('sessionexpired')
          if (sessionexpired == "true") {
            // await setState({ sessionExpiryModal: true })
            setSessionExpiryModal(true);
          }
          let responseData = [];
          let recordsLength = 0;
          if (response && response.details && response.details.length > 0) {
            responseData = response.details;
            recordsLength = response.details.length;
          } else {
            if (response && ((response[apiResponseKey] && response[apiResponseKey]?.length >= 0) || (response[props.secondaryResponseKey] &&  response[props.secondaryResponseKey]?.length > 0))) {
              if (response.pagination && response.pagination.totalCount) {
                recordsLength = response.pagination.totalCount;
              }
              if(response.pagination?.page) {
                currentPageRef.current  = response.pagination?.page;
                if(response.pagination?.page === 1) {
                  let filter = filterCriteria;
                  filter.page = 1;
                  setFilterCriteria(filter)
                }
              }
              if(response.pagination?.totalCount) {
                setTotalRecords(response.pagination?.totalCount)
              }
              responseData = updateSlNoToData(response[apiResponseKey], limit * (page - 1));
              if (props.secondaryResponseKey) {
                let secondaryResponse = response?.[props.secondaryResponseKey];
                if (secondaryResponse) {
                  updateSlNoToData(response[props.secondaryResponseKey], limit * (page - 1))
                  responseData = [...responseData, ...secondaryResponse];
                  numberOfFoldersRef.current = response['folders']?.length;
                  if(response['folders']?.length < filterCriteria?.limit) {
                    setFolderLoading(false);
                  } else {
                    setFolderLoading(true);
                  }
                }
              }
              if(type === 'onScroll' || (filterCriteria?.page !== 1 && (props.type === 'Folders'))) {

                setPreviousData(allUsersData)
                responseData = formatFoldersAndFiles(allUsersData, responseData);

              } else if (props.type === 'Folders') {
                isThrottled.current = true;
                setTimeout(() => {
                  isThrottled.current = false;
                }, 300);
              }
              setServerResponse(response)
              // responseData = updateDateFormat(responseData, dateFormat);
            } else {
              if(type === 'onScroll') {
                responseData = allUsersData
              }
              if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount == 0)) {
                recordsLength = response.pagination.totalCount;
              }
            }
          }

          setAllUsersData(responseData);
          setTimeout(() => {
            if(responseData?.length == 0) {
              setNoData(true)
            } else {
              setNoData(false)
            }
          }, 100);
          setIsLoading(false);
          if (filterCriteria.page === 1) {
            setTotalRecordsLength(recordsLength);
          }
          return responseData;
        }).catch((err) => {
          return err;
        });
    }
  }


  const getAPIUrl = () => {
    return props.apiUrl;
  }
  const getFilterUrl = (filterCriteria, type) => {
    return props.apiUrl;
  }

  const checkedFields = (data) => {
    getDataToExport(data)
  }




  //Get all data of current screen with filters applied from server to export to CSV
  const getDataToExport = async (data) => {
    setIsLoading(true);
    let filter = { ...filterCriteria };
    delete filter['limit'];
    delete filter['page'];
    let url = getAPIUrl();
    let apiUrl;
    apiUrl = `${url}?filter=${JSON.stringify(filter)}&type=exportToCsv`;
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        let sessionexpired = await localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          setSessionExpiryModal(true)
        }
        if (response && response[url]) {
          let exportData = response[url];
          exportData.forEach((item) => {
            for (let key in item) {
              let formFields = props.formFields();
              /**@Find the Corresponding @FormField based on @key in each iteration */
              let findFormFieldBasedOnEachKey = formFields.find(formField => formField.name === key)
              if (findFormFieldBasedOnEachKey) {

                /**@HandlingMultipleAutoRelateFields */
                if (findFormFieldBasedOnEachKey.type == "relateAutoComplete" && findFormFieldBasedOnEachKey.isMultiple) {
                  if (item[key].length > 0) {
                    item[key] = item[key].map(arrItem => arrItem[findFormFieldBasedOnEachKey.searchField]).toString();
                  }
                }
                /**@HandlingAutoRelateFields */
                if (findFormFieldBasedOnEachKey.type === "relateAutoComplete" && !findFormFieldBasedOnEachKey.isMultiple) {
                  item[key] = item[key][findFormFieldBasedOnEachKey.searchField]

                  /**@Deletx`e the Key we are using @Search Ex {Project+Search} */
                  delete item[key + "Search"];
                }
                /**@HandlingDates */
                if (findFormFieldBasedOnEachKey.type === "date") {
                  let date = moment(item[key]).format(findFormFieldBasedOnEachKey?.dateFormat)
                  item[key] = date;
                }
              }

              /**@DeletingUnWantedInformation Like @_id @__V @createdBy @active {MONGO FIELDS USED IN BACKEND} */
              delete item._id;
              delete item.__v;
              delete item.createdBy;
              delete item.active;

              for (const key in data) {
                if (data[key] === false) {
                  delete item[key];
                }
              }
            }
          });
          setExportData(exportData);
          setIsLoading(false);
          csvLinkRef.current.link.click();
        }
      }).catch((err) => {
        setIsLoading(false);
        return err;
      });
  }

  //open Form modal
  const openFormModal = async (rowData, type, customType) => {
    setFormType(type ? type : 'add');
    if (customType === 'upload' && props.uploadDetails) {
      setCustomFormData(props.uploadDetails)
      setTimeout(() => {
        setIsOpenFormModal(true);
      }, 300);
    } else if (customType === 'files') {
      setCustomFormData({ formUrl: 'files', type: 'Files' })
      setTimeout(() => {
        setIsOpenFormModal(true);
      }, 300);
    }
    else {
      setIsOpenFormModal(true);
    }
    setSelectedRows('');
    setSelectedId(rowData._id);
    if (type === 'view') {
      if (isOpenFormModal && formModalRef) {
        await formModalRef.handleViewDisplay(rowData, type);

      }
    }
    if (type == 'edit') {
      setTableRowData(rowData);
      if (isOpenFormModal && formModalRef) {
        await formModalRef.getRowData(rowData, 'edit', customType);
      }
    }
  }

  //close form modal
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
    setRedirectToRoute(true);
    if (customFormData) {
      setCustomFormData(null);
    }
  }

  const submitActionsData = async (method, url, body) => {
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          setSessionExpiryModal(true);
        }
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          if(props.softDelete) {
            softDelete(selectedRows[0]._id)
          } else {
            getDataFromServer();
          }
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
        setOpenDeleteModal(false);
        setActions('');
        setSelectedRows('');
        setSelectedRowsId('');
      }).catch((err) => {
        return err;
      });
  }
  const handleActions = async (apiType) => {
    let ids = selectedRows.map(id => id._id)
    let method, url, body = {}
    if (props.type === "Folders") {
      let apiUrl = deleteType ? "files" : "folders";
      url = `${apiUrl}/remove/${selectedRows[0]._id}`;
      method = "PUT";
      const originalPath = selectedRows[0].path;
      const prefix = "server/upload/folders/";
      const newPath = originalPath.replace(prefix, "");
      body = {
        path: newPath
      };
      submitActionsData(method, url, body);
    } else if (props.type === "Files") {
      let apiUrl = "files";
      url = `${apiUrl}/remove/${selectedRows[0]._id}`;
      method = "PUT";
      const originalPath = selectedRows[0].path;
      const prefix = "server/upload/folders/";
      const newPath = originalPath.replace(prefix, "");
      body = {
        path: newPath
      };
      submitActionsData(method, url, body);
    }
    else {
      if (confirmType === 'Delete' || confirmType === 'Block' || confirmType === 'UnBlock') {
        url = `${apiUrl}/${apiType}`;
        method = 'POST';
        body = {
          selectedIds: ids
        }
      }
      submitActionsData(method, url, body)
    }

  }
  // delete selected row
  const deleteSelectedRow = async () => {
    if (confirmType === 'Delete') {
      handleActions('multiDelete')
    } else if (confirmType === 'Block') {
      handleActions('multiblock?block=true')
    } else if (confirmType === 'UnBlock') {
      handleActions('multiblock?unblock=true')
    } else if (confirmType === 'ResetPassword') {
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
      setSelectedRows(selectedRows);
    }
  }

  //close delete modal
  const closeDeleteModal = async () => {
    setOpenDeleteModal(false);
    setOpenSharedModal(false)
    setOpenMoveFolder(false)
    setOpenUplodeModal(false)
    setActions('');
  }

  const sharedFiles = async (rowData, selectActions, customType, filterCriteria) => {
    if (props.type === 'SharedFiles' && rowData && rowData.type && rowData.fileId) {
      filterCriteria['criteria'] = [{ key: "fileId", value: rowData.fileId._id, type: 'eq' }];

    } else if (props.type === 'SharedFiles' && rowData && rowData.folderId) {
      filterCriteria['criteria'] = [{ key: "folderId", value: rowData.folderId._id, type: 'eq' }];
    }
    else if (rowData && props.shareRequired) {
      filterCriteria['criteria'] = [{ key: rowData.type ? "fileId" : "folderId", value: rowData._id, type: 'eq' }];
    }
    fetchMethodRequest('GET', `sharedFiles?type=${'shared'}&filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response.sharedfiles) {
        setSharedFilesResponse(response.sharedfiles)
      }
    });
    let selectedRowsId = [];
    selectedRowsId.push(rowData)
    setSelectedRows(selectedRowsId);
    setTimeout(() => {
      setOpenSharedModal(true)
    }, 100);

  }

  const summarizeAFile = (details) => {
    if((props.type === 'SharedFiles' && details.fileId?._id) || (details && details?._id)) {
      setSidePanelType('summary')
      setIsSidePanelLoading(true)
      let body = {
        fileId : props.type === 'SharedFiles' ? details.fileId?._id : details._id
      }
      setOpenSidePanel(true);
      setSidePanelDetails({fileName : props.type === 'SharedFiles' ? details.fileId?.name : details?.name,fileId: props.type === 'SharedFiles' ? details.fileId?._id : details?._id})
      fetchMethodRequest('POST',apiCall.summary,body).then((resp) => {
        if(resp && resp?.summaryDetails) {
          setSidePanelDetails({...resp?.summaryDetails,...{fileName : props.type === 'SharedFiles' ? details.fileId?.name : details?.name,fileId: props.type === 'SharedFiles' ? details.fileId?._id : details?._id}})
          setIsSidePanelLoading(false)
        }
      })
    }
  }

  const askAQuestion = (details) => {
    if(details && details?._id) {
      if((props.type === 'SharedFiles' && details.fileId?._id) || (details && details?._id)) {
        setSidePanelDetails({fileName : props.type === 'SharedFiles' ? details.fileId?.name : details?.name,fileId: props.type === 'SharedFiles' ? details.fileId?._id : details?._id , ...details})
        setSidePanelType('askQuestions')
        setTimeout(() => {
          setOpenSidePanel(true);
        }, 100);
      } else {
        showToasterMessage(configMessages.detailsNotFound, "error");
      }
    }  else {
      showToasterMessage(configMessages.detailsNotFound, "error");
    }
  
  }

  const handleShowOptions = (e,item) => {
    e.stopPropagation()
    if(activeOptionId === item._id) {
      setActionOptionId(null)
    } else {
      setActionOptionId(item._id)
    }
  }

  const closeSidePanel = () => {
    setOpenSidePanel(false);
    setSidePanelDetails('');
    setIsSidePanelLoading(false)
  }
  
  const moveFolders = async (rowData) => {
    let selectedRowsId = [];
    selectedRowsId.push(rowData)
    setSelectedRows(selectedRowsId);
    setMoveFolderId(rowData._id)
    // setMoveFileType(rowData._id)
    setOpenMoveFolder(true)
  }

  const MultiFolderUplode = async(rowData)=>{
    setOpenUplodeModal(true)
  }
  const setDeleteRecords = async (rowData, selectActions, customType) => {
    let selectedRowsId = [];
    selectedRowsId.push(rowData)
    setSelectedRows(selectedRowsId);
    setConfirmType("Delete");
    setDeleteType(customType)
    deleteConfirmAction(rowData, selectActions, customType)
  }

  // conformation for delete item
  const deleteConfirmAction = async (rowData, selectActions, customType) => {
    let selectedRowsId = [];
    if (selectedRows && selectedRows.length && selectedRows.length > 0) {
      selectedRows.forEach((item, index) => {
        selectedRowsId.push(item._id)
      })
      setSelectedRowsId(selectedRowsId);
      setOpenDeleteModal(true);
      setSelectActions(selectActions);
    }
    if (rowData) {
      setDeleteRowDataId(rowData);
      setOpenDeleteModal(true);
      setSelectActions(selectActions);
    }
  }

  //change dropdown elememt
  const changeDropDownElement = (event) => {
    dt.filter(event.target.value, event.target.name, 'equals');
  }


  const getTableFieldItem = (field , type) => {
      let tableFields = type && type === 'secondaryTable' ? props?.secondaryTableFields() : type && type === 'expandtable' ? props?.expandTableFields() : tablefieldsToShow
    for (let i = 0; i < tableFields.length; i++) {
      if (tableFields[i].field == field) {
        return tableFields[i];
      }
      //condition to match fieldType
      if (tableFields[i].fieldType == field) {
        return tableFields[i]['field'];
      }
    }
    return null;
  }

  const getColorFromOptions = (options, name) => {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if ((options[i].value == name) && options[i].color) {
          return options[i].color;
        }
      }
    }
    return 'success';
  }
  const getUserData = (_id, type) => {
    let data = allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }

  const getIconValue = (rowData, labelKey) => {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }

  const getAUserData = (_id, type) => {
    let data = allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return data[i]
      }
    }
  }

  const onOpenViewModal = async (rowData, type, from) => {
    let rowDataIndex = getUserData(rowData['_id'], '_id');
    if (from == "route") { rowData = getAUserData(rowData['_id'], '_id') }
    let _id = rowData['_id'];
    let status = rowData['status'] ? rowData['status'] : ''
    let keys = Object.keys(rowData);

    let formFields = [];
    if (formFields) {
      if (props.type) {
        if (rowData) {
          let values, fieldType, searchField, self = this, icon = false;

          // hari get all the labels from 
          keys.forEach(async function (key) {
            let labelKey = await self.getTableFieldItem(key);
            if (labelKey == null) {
              labelKey = key;
            } else {
              let val = rowData[key];
              if (labelKey.fieldType === 'icon') {
                val = self.getIconValue(rowData, labelKey);
                icon = true;
              }
              fieldType = labelKey.fieldType ? labelKey.fieldType : null
              searchField = labelKey.searchField ? labelKey.searchField : null
              let options = labelKey.options ? labelKey.options : []
              labelKey = labelKey.header
              if (val) {
                if (fieldType && searchField && fieldType == "relateAutoComplete") {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key][searchField],
                    fieldType: fieldType
                  }
                } else {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key],
                    fieldType: fieldType,
                    options: options
                  }
                }
                formFields.push(values);
                icon = false;
              }
            }
          });
        }
      }
      if (displayViewOfForm === 'modal') {
        setIsOpenFormModal(true);
        setUserStatus(status);
        setFormType(type);
        await formModalRef.getViewData(formFields, 'view', rowDataIndex, rowData, _id);
      } else if (displayViewOfForm === 'screen') {
        let displayBreadCrumbField = getTableFieldItem('Link');
        setIsOpenFormModal(true);
        setUserStatus(status);
        setFormType(type);
        setDisplayBreadCrumbField(displayBreadCrumbField);
        setDisplayBreadCrumbValue(rowData[displayBreadCrumbField])
      }
    }
    await getDataFromServer();

  }

  const closeViewModal = async () => {

    setOpenViewModal(false);
  }
  //on changing pagination
  const onPageChange = async (event) => {
    let filter = { ...filterCriteria };
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filter['limit'] = event.rows;
      filter['page'] = currentPage;
      filter['criteria'] = saveCriteria.criteria;
      if (Object.keys(savedFiltCriteria).length !== 0) {
        filter['criteria'].push(...savedFiltCriteria.criteria);
      }

      setRows(event.rows);
      setFirst(event.first);
      setPage(event.page + 1);
    }
    setFilterCriteria(filter)
    getDataFromServer(filter)
  }

  const onPageChangeAccordion = async (event) => {
    let filterCriteria = filterCriteria;
    let id = openId;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
    }
  }
  //sorting fields
  const sortChange = (event) => {
    const relateAutoCompleteFields = props.formFields().filter(field => field.type === "relateAutoComplete").map(field => field.fieldName);
    setSelectedRows('');
    let sort = sortCount;
    let criteria = filterCriteria
    if (event && event['sortField']) {
      sort = sort == 0 ? sort + 1 : 0;
      let sortField = event['sortField'];
      setsortField(sortField)
      setSortOrder(event.sortOrder)
      if (relateAutoCompleteFields.includes(sortField)) {
        sortField = sortField + 'Search';
      }
      criteria = {
        criteria: saveCriteria.criteria,
        direction: sort == 0 ? "desc" : 'asc',
        sortfield: sortField,
        limit: rows,
        page: page ? page : 1,
      }
      setIsLoading(true)
      setSortCount(sort);
      setFilterCriteria(criteria);
      getDataFromServer(criteria);
    }
  }

  const confirmActionType = async (type) => {
    if (type === 'Delete') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Delete')
      deleteConfirmAction()
    } else if (type === 'Block') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Block')
      deleteConfirmAction()
    } else if (type === 'ResetPassword') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Block');
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
      setSelectedRows(selectedRows);
    } else if (type === 'Send Email') {
      setOpenSendEmailModal(true);
    }
  }

  //onActionsChange
  const onActionsChange = async (event, type) => {
    if (type == 'dropdownFilter') {
      for (let i in actionsTypes) {
        if (actionsTypes[i].value === event.target.value) {
          if (actionsTypes[i].action) {
            actionsTypes[i].action(selectedRows, actionsTypes[i].field, actionsTypes[i].value)
            getDataFromServer();
            return;
          }
        }
      }
      confirmActionType(event.target.value)
    }
  }

  const rowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: '14rem' }}
      />
    );
  };

  const selectFilterTemplate = (options) => {
    return (
      <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
    );
  };

  const onMultiSelectFilterChange = async (key, value, type, index) => {
    let filterCriteria = filterCriteria;
    if (filterCriteria && type === 'push') {
      let v = [], length = '';
      if (filterCriteria['criteria'] && filterCriteria['criteria'][index] && filterCriteria['criteria'][index]["value"]) {
        v = [...filterCriteria['criteria'][index]["value"]];
        length = v.length;
        v[length] = value;
        filterCriteria['criteria'][index]["value"] = v;
      } else {
        v[0] = value
        filterCriteria['criteria'].push({
          key: key,
          value: v,
          type: 'in'
        });
      }
      setFilterCriteria(filterCriteria)
      await getDataFromServer(filterCriteria)
    } else {
      getDataFromServer(filterCriteria)
    }
  }

  const getCriteriaOfSavedFilt = (jsonq) => {
    setSavedFiltCriteria(jsonq);
  }

  const convertToContextType = (str) => {
    const words = str.split(' ');
    words[0] = words[0].toLowerCase();
    const camelCased = words.join('');
    return camelCased;
  };

  const highlightFliter = (option) => {
    const filterInputFields = document.querySelectorAll('.p-column-filter-menu-button.p-link');
    filterInputFields.forEach(button => {
      const subjectText = button.closest('.p-column-header-content').querySelector('.p-column-title').textContent;
      // const headerString = formatPropType(subjectText).charAt(0).toLowerCase() + formatPropType(subjectText).slice(1, -1);
      const headerString = convertToContextType(subjectText)
      if (option == headerString) {
        return button.classList.add('p-column-filter-menu-button-active');
      }
    });
  }


  const onFilterColumns = (event) => {
    let res = FunnelFilter(event, fieldMap, "c", sortCount, sortFieldForFunnel, props.type, rows, saveCriteria.criteria, savedFiltCriteria);
    // for setting the current Page to 1 ;
    currentPageRef.current = 0;
    setSaveCriteria(res);
    getDataFromServer(res);

    let filters = [];
    Object.keys(event.filters).forEach(key => {
      if (event.filters[key].constraints[0].value !== null && event.filters[key].constraints[0].value !== "" && event.filters[key].constraints[0].value.length !== 0) {
        filters.push(key);
        if (key == 'createdByName') {
          key = 'createdBy'
        } else if (key == 'updatedByName') {
          key = 'updatedBy'
        } else if (key == 'desc') {
          key = 'description'
        }
        highlightFliter(key)
      }
    });
    setFilterCriteria(res);
  }

  const getOneDayQuery = (date) => {
    let todayDate = moment(new Date(date)).format('YYYY-MM-DD');
    return { $lte: new Date(todayDate + 'T23:59:59Z'), $gte: new Date(todayDate + 'T00:00:00Z') };
  }
  const getPreviousDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      date.setDate(date.getDate() - 1);

      // Format the date in 'YYYY-MM-DD' format
      const formattedDate = date.toISOString().split('T')[0];

      return formattedDate;
    } else {
      return null; // or handle the case where date is not provided
    }
  };
  const getNextDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      date.setDate(date.getDate() + 1);

      // Format the date in 'YYYY-MM-DD' format
      const formattedDate = date.toISOString().split('T')[0];

      return formattedDate;
    } else {
      return null; // or handle the case where date is not provided
    }
  };

  const formatDateFilters = (filterObj, field) => {
    if (filterObj.value && filterObj.value.length === 2) {
      if (filterObj.value[1] !== null) {
        const start = dateFormats.formatDate(filterObj.value[0], config.dayYearDateFormat);
        const initialDate = getPreviousDate(start);
        const inputDateForNext = dateFormats.formatDate(filterObj.value[1], config.dayYearDateFormat);
        const nextDate = getNextDate(inputDateForNext);
        return [
          { key: field, value: initialDate, type: 'gte' },
          { key: field, value: nextDate, type: 'lt' }
        ];
      } else {
        const inputDate = dateFormats.formatDate(filterObj.value[0], config.dayYearDateFormat);
        const previousDate = getPreviousDate(inputDate);
        let date = getOneDayQuery(previousDate);
        return [{ key: field, value: previousDate, type: 'gte' }, { key: field, value: inputDate, type: 'lte' }]

      }
    } else {
      return [];
    }
  }




  // on search get data from server
  const onFilterChange = async (event, type) => {
    setIsLoading(true);
    setSelectedRows('');
    if (type === 'dropdownFilter' || type === 'date') {
    }
    let fieldName = '', filter = { ...filterCriteria },
      selectedFilterValue, selectedFilter, selecterFilterType, formattedTime, formattedDate, isDateFilter = false;
      filter.page = 1;
      currentPageRef.current = 0;
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        if (fieldName == 'date' || fieldName == 'created' || fieldName == 'updated') {
          isDateFilter = true;
          selectedFilterValue = selectedFilter && selectedFilter.value && selectedFilter.value.length == 10 ?
            selectedFilter.value : null;
          let date = dateFormats.addDaysToDate(selectedFilter.value, 1);
          formattedDate = dateFormats.formatDate(date, config.dayYearDateFormat);
          selecterFilterType = 'eq'
        } else {
          selecterFilterType = 'regexOr'
          selectedFilterValue = selectedFilter && selectedFilter.value ? selectedFilter.value : null;
        }
      } else {
        fieldName = event.target.name;
        selectedFilterValue = event && event.target && event.target.value ? event.target.value : null;
        setGlobalSearch(selectedFilterValue);
      }
      if (type == 'dropdownFilter') {
        selecterFilterType = 'in'
      }
      if ((type == 'dropdownFilter' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length > 0) || (type !== 'date' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length >= 3)) {
        if (fieldName == 'createdBy') {
          fieldName = 'createdBy.name';
        }
        if (fieldName == 'phone') {
          fieldName = 'phone';
        }
        if (fieldName == 'globalSearch') {
          filter.globalSearch = {
            value: selectedFilterValue,
            type: 'user'
          }
          if (event.target.value.length == 0) {
            delete filter.globalSearch;
          }
        } else {
          if (selecterFilterType == 'gte') {
            let obj = filter.criteria.find(x => x.key == fieldName);
            let index = filter.criteria.indexOf(obj);
            if (index != -1) {
              filter['criteria'].splice(index, 1, {
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            } else {
              filter['criteria'].push({
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            }
          } else {
            if (fieldName == 'updated') {
              fieldName = 'updated.name'
            }
            if (filter['criteria'].length == 0 && selecterFilterType != 'lte') {
              filter['criteria'].push({
                key: fieldName,
                value: selectedFilterValue,
                type: selecterFilterType
              });
            } else {
              let obj = filter.criteria.find(x => x.key == fieldName);
              let index = filter.criteria.indexOf(obj);
              if (selecterFilterType == 'lte') {
                if (selectedFilterValue.length == 10) {
                  filter['criteria'].splice(0, 1, ({
                    key: fieldName,
                    value: selectedFilterValue.substring(6, 10) + '-' + selectedFilterValue.substring(3, 5) + '-' + selectedFilterValue.substring(0, 2) + 'T23:59:59Z',
                    type: selecterFilterType
                  }));
                }
              }
              if (index != -1 && selecterFilterType != 'lte') {
                filter['criteria'].splice(index, 1, {
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              } else if (selecterFilterType != 'lte') {
                filter['criteria'].push({
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              }
            }
          }
          // setFilterCriteria(filter);
        }
        setFilterCriteria(filter);
        await getDataFromServer(filter)
      } else if (type === 'date' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length > 0) {
        if (selectedFilterValue.length == 2) {
          let fieldIndex1 = filter.criteria.findIndex((obj) => obj.key == fieldName)
          let fieldIndex2 = filter.criteria.findIndex((obj) => obj.key == fieldName)
          if (fieldIndex1 >= 0) filter.criteria.splice(fieldIndex1, 1)
          if (fieldIndex2 >= 0) filter.criteria.splice(fieldIndex2, 1)
          let startDate = (moment(selectedFilterValue[0]).format('YYYY-MM-DD'));
          let eDate = selectedFilterValue[1] ? JSON.parse(JSON.stringify(selectedFilterValue[1])) : JSON.parse(JSON.stringify(selectedFilterValue[0]))
          eDate = new Date(eDate)
          let endDate = eDate.setDate(eDate.getDate() + 1);
          endDate = (moment(endDate).format('YYYY-MM-DD'));
          filter['limit'] = 20;
          filter['page'] = 1;
          filter.criteria.push({ 'key': fieldName, 'value': startDate, 'type': 'gte' })
          filter.criteria.push({ 'key': fieldName, 'value': endDate, 'type': 'lte' })
        }
        setFilterCriteria(filter);
        await getDataFromServer(filter)
      } else {
        if ((selectedFilterValue == null || (type === 'dropdownFilter' && selectedFilterValue.length == 0)) && !isDateFilter) {
          let obj = filter.criteria.find(x => x.key == fieldName);
          let index = filter.criteria.indexOf(obj);
          filter.criteria.splice(index, 1);
          if (fieldName == 'globalSearch') {
            filter.globalSearch = {}
            delete filter.globalSearch
          }
          setFilterCriteria(filter);
          await getDataFromServer(filter)
        }
      }
      setIsLoading(false);
    }
  }

  //open Bulk Modal
  const bulkUploadMoadal = () => {
    setOpenBulkUploadModal(true);
  }

  //close Bulk Modal
  const closeBulkModal = async () => {
    setOpenBulkUploadModal(false);
  }

  //open export to csv Modal
  const exportToCsvModal = () => {
    setOpenExportToCsvModal(true);
  }

  //close export to csv Modal
  const closeExportToCsvModal = async () => {
    setOpenExportToCsvModal(false);
  }

  //select multiple rows to delete
  const onSelectRowsUpdate = async (event) => {
    if (isOpenFormModal && event.value.length > 0) {
      setSelectedRows([event.value[event.value.length - 1]])
    }
    else {
      setSelectedRows(event.value);
      await getActions()
    }
  }

  //openConfirmationModal
  const openConfirmationModal = async (rowData, status, type) => {
    setIsOpenFormModal(false);
    setOpenConfirmationModal(true);
    setItem(rowData);
    setConfirmText(type);
    setLeaveStatus(status);
  }

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  }
  const setFilterCriteriaForActivities = async (editRowDataID) => {
    let filterCriteria = filterCriteria;
    filterCriteria['criteria'].push(
      { key: 'contextId', value: editRowDataID, type: 'eq' }
    )
    setFilterCriteria(filterCriteria);
  }

  let getFileUpload = (i, item) => {
    const chooseOptions = { 
      icon: 'pi pi-upload',
      iconOnly: true,
      className : 'custom-fileupload'
    };
    const bytesPerMB = 1024 * 1024;
    let fileSize = item.maxFileSize * bytesPerMB;
    let url = '';
    if (props?.assingValueToForm?.path && props?.assingValueToForm?.parentFolderId) {
      url = `files?path=${props?.assingValueToForm?.path}&folderId=${props?.assingValueToForm?.parentFolderId}?type=${item.imagePath.toLowerCase()}`
    } else {
      url = 'files'
    }
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoFileUpload
              url={url}
              imagePath={item.imagePath.toLowerCase()}
              formType={formType}
              showPreview={true}
              input={field}
              id={field.id}
              label={item.label}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              type={props.type}
              isMultiple={item.isMultipleRequired}
              maxFileSize={fileSize}
              hideLabel={true}
              autoSubmit={true}
              modeType={'basic'}
              chooseOptions={chooseOptions}
              closeFormModal={() => {getDataFromServer()}}
              assingValueToForm={props.assingValueToForm}
              showCurrentFilesInProgressBar={showCurrentFilesInProgressBar}
              setFilesToProgressBar={setFilesToProgressBar}
              updateFileStatus={updateFileStatus}
              updateFileProgress={updateFileProgress}
            />)}
        />
      </div>
    )
  }

  function showCurrentFilesInProgressBar(show) {
    if(dropZoneRef.current?.setIsLoaderVisible) {
      dropZoneRef.current?.setIsLoaderVisible(show)
    }
  }

  function setFilesToProgressBar(files) {
    if(dropZoneRef.current?.setFiles){
      dropZoneRef.current?.setFiles(files)
    }
  }

  function updateFileStatus (index , progress , status) {
    if(dropZoneRef.current?.updateFileStatus){
      dropZoneRef.current?.updateFileStatus(index , progress , status)
    }
  }

  function updateFileProgress(status) {
    if(dropZoneRef.current?.setUploadProgress){
      dropZoneRef.current?.setUploadProgress(status);
    }
  }
  const getTooltipFromOtptions = (options, name) => {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].tooltip;
        }
      }
    }
    return '';
  }

  const getArrayBadges = (badge_values) => {
    let str = [...badge_values].join(',');
    return str;
  }

  const textEllipses = (item, column, data) => {
    return (
      <div className='textElipses'>
        <span onClick={() => openFormModal(item, 'view')} title={item[column.field]}>
          {data}
        </span >
      </div >
    )
  }

  //change table body values//
  const changeFieldValues = (item, column , type) => {
    let self = this, tableItem;
    tableItem = getTableFieldItem(column.field, type);

    const onDragStart = (e, item) => {
      e.dataTransfer.setData("text/plain", JSON.stringify(item));
      e.dataTransfer.effectAllowed = "move";
    };

    const onDragOver = (e) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = "move";
    };

    const onDrop = async (e, targetItem) => {
      e.preventDefault();
      const draggedData = JSON.parse(e.dataTransfer.getData("text/plain"));
      let url;
      if (draggedData) {
        if (draggedData && draggedData.type) {
          url = `files/fileMove/${draggedData._id}`;

        } else {
          url = `folders/moveFolder/${draggedData._id}`;

        }
        const body = {
          ...draggedData,
          destParentFolderId: targetItem._id,
          destType: targetItem.type,
          destPath: targetItem.path,
        };

        try {
          const res = await fetchMethodRequest("PUT", url, body);
          if (res && res.respMessage) {
            showToasterMessage(res.respMessage, "success");
            getDataFromServer();
          } else {
            showToasterMessage(res.errorMessage, "error");
          }
        } catch (err) {
          console.error("Fetch error:", err);
          showToasterMessage("Error moving files", "error");
        }
      }
    };

    let content;
    if (tableItem.fieldType === "fileUpload") {
      content = (
        <div className="textElipses">
          {item &&
            item[column.field] &&
            item[column.field].length > 0 ? (
            <Link
              to={`${config.imgUrl}${props.type.toLowerCase()}/${item[column.field][0]
                }`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              {item[column.field][0]}
            </Link>
          ) : null}
        </div>
      );
    } else if (tableItem.fieldType === "Link") {
      content = (
        <div className="textElipses">
          <div
            onClick={() => openFormModal(item, "view")}
            style={tableItem.style}
            title={item[column.field]}
          >
            {item[column.field]}
          </div>
        </div>
      );
    } else if (tableItem.fieldType === "Date") {
      content = (
        <div >
          { item[column.field] ?
              dateFormats.formatDate(
                item[column.field],
                  tableItem?.dateFormat ? tableItem?.dateFormat : config.dateDayMonthFormat
              ) : null}
      </div>
      );
    } else if (tableItem.fieldType === "time") {
      const timestamp = item[column.field];
      if (timestamp) {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        let period = 'AM';

        if (hours >= 12) {
          period = 'PM';

          // Convert 12-hour format to 12 AM/PM format
          if (hours > 12) {
            hours -= 12;
          }
        }
        // Format the time as "hh:mm AM/PM"
        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes} ${period}`;
        content = (
          <span style={tableItem.style} title={formattedTime}>
            {formattedTime}
          </span>
        );
      }
    } else if (tableItem.fieldType === "WebSite") {
      content = (
        <div className="textElipses">
          <a
            href={item[column.field]}
            title={item[column.field]}
            target="_blank"
          >
            {item[column.field]}
          </a>
        </div>
      );
    } else if (tableItem.fieldType === "Download") {
      content = (
        <div className="textElipses">
          <a
            href={item["duplicateFileDownloadUrl"]}
            title={item[column.field]}
            target="_blank"
          >
            {item[column.field]}
          </a>
        </div>
      );
    } else if (!item.type && props.type !== "SharedFiles" && tableItem.fieldType === "redirectable") {
      content = (
        <div className={`textElipsess ${ numberOfFoldersRef.current === (column.rowIndex + 1) ? 'last-folder' : ''}`} >
          <span title={item[column.field]}>
            <NavLink
              to={`/${tableItem.redirecTo}?searchId=${item._id}&searchName=${tableItem.redirectFilterField}`}
            >
              <span
                style={{ fontWeight: "bold"}}
              >
                {item && (
                  <FontAwesomeIcon
                    icon={
                      !item.type
                        ? "folder"
                        : item.type === "pdf"
                          ? "file-pdf"
                          : item.type === "docx"
                            ? "file-word"
                            : "file"
                    }
                    className="genderIconAlignment getcursor width-15 me-2"
                    style={{
                      color:
                        item.type && item.type === "pdf"
                          ? "brown"
                          : item.type === "docx"
                            ? "#1292ee"
                            : "#d777489c",
                    }}
                    data-toggle="tool-tip"
                    title="Settings"
                  />
                )}
                {item[column.field]}
              </span>
            </NavLink>
          </span>
        </div>
      );
    } 
     else if (
      item.type &&
      tableItem.fileViewType &&
      tableItem.fileViewType === "view"
    ) {
      content = (
        <div className="textElipsess file-row">
          <span title={item[column.field]}>
            {item.type && tableItem.fileViewType === "view" && (
              <div className="textElipsess">
                <FontAwesomeIcon
                  icon={
                    !item.type
                      ? "folder"
                      : item.type === "pdf"
                        ? "file-pdf"
                        : item.type === "docx"
                          ? "file-word"
                          : "file"
                  }
                  className="genderIconAlignment align_move"
                  style={{
                    color:
                      item.type && item.type === "pdf"
                        ? "brown"
                        : item.type === "docx"
                          ? "#1292ee"
                          : "#d777489c",
                  }}
                  data-toggle="tool-tip"
                  title="Settings"
                />
                <Link
                  to={`${config.imgUrl}${ props.type === "SharedFiles" ? item?.fileId?.path : item?.path}`}
                  target="_blank"
                >
                  <span className="px-1" style={{ fontWeight: "bold" }}>
                    {props.type === "SharedFiles"
                      ? item?.fileId?.name || item?.folderId?.name
                      : item[tableItem.field]}
                  </span>
                </Link>
              </div>
            )}
          </span>
        </div>
      );
    } 
    else if (
      props.type === "SharedFiles" &&
      tableItem.fieldType &&
      tableItem.fieldType === "redirectable"
    ) {
      content = (
        <div className="textElipsess file-row">
          <span title={item[column.field]}>
            <NavLink
              to={`/${tableItem.redirecTo}?searchId=${item._id}&searchName=${tableItem.redirectFilterField}`}
            >
              <span
                style={{ fontWeight: "bold"}}
              >
                {item && (
                  <FontAwesomeIcon
                    icon={
                      !item.type
                        ? "folder"
                        : item.type === "pdf"
                          ? "file-pdf"
                          : item.type === "docx"
                            ? "file-word"
                            : "file"
                    }
                    className="genderIconAlignment getcursor width-15 me-2"
                    style={{
                      color:
                        item.type && item.type === "pdf"
                          ? "brown"
                          : item.type === "docx"
                            ? "#1292ee"
                            : "#d777489c",
                    }}
                    data-toggle="tool-tip"
                    title="Settings"
                  />
                )}

                {item?.type ? item?.fileId?.name : item?.folderId?.name}
              </span>
            </NavLink>
          </span>
        </div>
      );
    } else if (!tableItem.isMultiSelect && tableItem.fieldType === "dropDown") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      content = <Badge color={mcolor} pill style={tableItem.style}>{item[column.field]}</Badge>;
    } else if (tableItem.isMultiSelect && tableItem.fieldType === "dropDown") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      content = (
        <div>
          {item[column.field] == undefined
            ? []
            : item[column.field].map((badge, index) => (
              <Badge
                key={index}
                color={mcolor}
                pill
                style={{ ...tableItem.style, margin: "1px" }}
              >
                {badge}
              </Badge>
            ))}
        </div>
      );
    } else if (tableItem.fieldType === "Badge") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      content = <Badge color={mcolor} pill style={tableItem.style}>{item[column.field]}</Badge>;
    } else if (tableItem.fieldType === "Role") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      content = <Badge color={mcolor} style={tableItem.style}>{item[column.field]}</Badge>;
    } else if (tableItem.fieldType === "icon") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      let tooltip = getTooltipFromOptions(tableItem.options, item[column.field]);
      content = (
        <FontAwesomeIcon
          style={{ color: mcolor, cursor: tooltip ? "pointer" : "" }}
          color="white"
          icon={tableItem.iconName}
          data-toggle="tool-tip"
          title={tooltip}
        />
      );
    } else if (tableItem.fieldType === "Array") {
      let val = flattenArray(item[column.field]);
      content = (
        <span style={tableItem.style} title={val}>
          {val}
        </span>
      );
    } else if (tableItem.fieldType === "Complex") {
      let data = ObjectbyString(item, column.field);
      content = <span>{data}</span>;
    } else if (tableItem.fieldType === "Actions") {
      content = (
        <div className='position-relative action-options'>
        <div 
        title='Options'
        onClick={(e) => handleShowOptions(e,item)}>
          <FontAwesomeIcon
                  className='genderIconAlignment getcursor summarize-icon default-icon-color width-15 margin-right-7'
                  color='white'
                  icon={faEllipsisV}
                  data-toggle='tool-tip'
                />
        </div>
        {activeOptionId && activeOptionId === item?._id && <ul ref={menuRef} className={`dropdown-menu d-flex flex-column justify-content-start options-menu right-70-percent ${openUpward}`}>
      {(item.type) ?
            <li className='dropdown-item'
              title='Ask a Question'
              onClick={() => askAQuestion(item)}
            >
              <FontAwesomeIcon
              className='genderIconAlignment getcursor summarize-icon default-icon-color width-15 me-2'
              color='white'
              icon={faQuestionCircle}
              data-toggle='tool-tip'   
            />
            Ask a Question
            </li>
            : null}

        {(item.type) ?
            <li className='dropdown-item'
              title='Summarize Document'
              onClick={() => summarizeAFile(item)}
            >
              <FontAwesomeIcon
              className='genderIconAlignment getcursor summarize-icon default-icon-color width-15 me-2'
              color='white'
              icon={faClipboardList}
              data-toggle='tool-tip'
            />
            Summarize a File
            </li>
            : null}
          {(props.type === 'SharedFiles' && item.permissions === 'Edit' && props.shareRequired) || (props.displayName === 'Home' && props.shareRequired) ?
            <li className='dropdown-item'
              title='share'
              onClick={() => sharedFiles(item, 'share', item.type, filterCriteria)}
            >
              <FontAwesomeIcon
              className='genderIconAlignment getcursor default-icon-color width-15 me-2'
              color='white'
              icon='user-plus'
              data-toggle='tool-tip'
            />
            Share
            </li>
            : null}
          {props.moveRequired && (
           <li className='dropdown-item'
            title='Move'
            onClick={() => moveFolders(item)}
           >
             <FontAwesomeIcon
              className='genderIconAlignment align_move getcursor default-icon-color me-2'
              icon='file-export'
              data-toggle='tool-tip'
            />
            Move
           </li>
          )}
          {props.preview && (
            <li className='dropdown-item'
            title='Preview'
            onClick={() => openPreviewModal(item)}
            >
              <FontAwesomeIcon
              className='genderIconAlignment getcursor width-15 margin-right-9'
              color='#17a2b8'
              icon='file'
              data-toggle='tool-tip'
              style={{
                color: '#17a2b8'
              }}
            />
            </li>
          )}
          {props.editRequired && (
           <li className='dropdown-item'
              title='Edit'
              onClick={() => {
                openFormModal(item, 'edit', !item.type ? 'folders' : 'files');
              }}
           >
             <FontAwesomeIcon
              className='genderIconAlignment getcursor default-icon-color width-15 margin-right-9'
              color='white'
              icon='pen'
              data-toggle='tool-tip'
            />
            Edit
           </li>
          )}
          {props.deleteRequired && (
            <li className='dropdown-item'
              title='Delete'
              onClick={() => setDeleteRecords(item, 'Delete', item.type)}
            >
              <FontAwesomeIcon
              className='genderIconAlignment getcursor me-2'
              color='white'
              icon='trash'
              data-toggle='tool-tip'
              style={{
                color: '#bf1725',
                width: '13',
              }}
            />
            Delete
            </li>
          )}
          </ul>}
      </div>
      );
    } else if (tableItem.fieldType === "relateAutoComplete") {
      if (tableItem.isMultiple) {
        let data = "";
        if (
          tableItem.searchField &&
          item[column.field] &&
          item[column.field].length > 0
        ) {
          for (let obj of item[column.field]) {
            data = obj[tableItem.searchField] + "," + data;
          }
        }
        content = (
          <span style={tableItem.style} title={data}>
            {tableItem.isClickable
              ? textEllipses(item, column, data)
              : data}
          </span>
        );
      } else {
        content =
          (tableItem.searchField &&
            item[column.field] &&
            item[column.field][tableItem.searchField] ? (
            <span style={tableItem.style} title={item[column.field][tableItem.searchField]}>
              {item[column.field][tableItem.searchField]}
            </span>
          ) : null);
      }
    } else if (
      tableItem.fieldType !== "relateAutoComplete" &&
      tableItem.isClickable &&
      tableItem.isClickable === true
    ) {
      content = (
        <div className="textElipses">
          <span onClick={() => openFormModal(item, "view")} title={item[column.field]}>
            {item[column.field]}
          </span>
        </div>
      );
    } else if (tableItem.fieldType === "RACSubField") {
      content =
        tableItem.parentField &&
          item[tableItem.parentField] &&
          item[tableItem.parentField][tableItem.subFieldName] ? (
          <span style={tableItem.style} title={item[tableItem.parentField][tableItem.subFieldName]}>
            {item[tableItem.parentField][tableItem.subFieldName]}
          </span>
        ) : null;
    } else if (tableItem.fieldType === 'customType') {
      return customChangeFieldValues(item  , tableItem)
    } else {
      if (item[column.field] && typeof item[column.field] !== "object") {
        content = (
          <span style={tableItem.style} title={item[column.field]}>
            {item[column.field]}
          </span>
        );
      }
    }

    return (
      <div
        draggable
        onDragStart={(e) => onDragStart(e, item)}
        onDragOver={(e) => onDragOver(e)}
        onDrop={(e) => onDrop(e, item)}
      >
        {content}
      </div>
    );
  };




  const clickConfirm = () => {
    closeConfirmationModal();
  }
  const ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
  const rowClassName = (item) => {
    if (item.status === 'Blocked') {
      return (
        { 'p-highlightRow': (item.status === 'Blocked') }
      )
    }
  }
  const openPreviewModal = async (item) => {
    setIsPreviewModal(true);
    setEmailTemplateData(item ? item : item);
  }
  const closePreviewModal = () => {
    setIsPreviewModal(false);
    setEmailTemplateData('');
  }
  const closeSendEmailModal = () => {
    setOpenSendEmailModal(false);
    setActions('');
  }
  const handleDateInterval = (startDate, endDate) => {
    if (startDate) {
      setStartDate(startDate);
    }
    if (endDate) {
      setEndDate(endDate);
    }
  }
  // ShowHideColumns Button Click Handler
  //Settings Modal open
  const openShowHideColumnsModal = () => {
    setIsOpenShowHideColumnsModal(true);
  }
  const getSettings = async () => {
    props.getSettings();
  }

  // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal
  const closeShowHideColumnsModal = async (type, columnFields, changedTableFields) => {
    if (type && type === 'confirm' && columnFields) {
      let updatedOrder = [];
      let fields = [...changedTableFields];
      setIsOpenShowHideColumnsModal(false);
      if (viewType === 'grid') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.mobile = value;
            }
          });
        })
      }
      if (viewType === 'list') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.show = value;
            }
          });
        })
      }
      fields.map(async col => {
        updatedOrder.push(
          {
            ...col,
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile,
            label: col.label ? col.label : col.header
          }
        )
      });

      /* GetListPreferenceId */
      let setPreferenceValue = {};
      setPreferenceValue[formatPropType(props.type)] = updatedOrder;
      fetchMethodRequest("PUT", `listPreferences/${listPreferences}`, setPreferenceValue)
      setOriginalTableFields(updatedOrder);
      setTimeout(() => {
        setTablefieldsToShow(updatedOrder);
      }, 500)
      await getTableFieldsOrder(originalTableFields);
    } else if (type && type === 'confirm') {
      let fields = [...changedTableFields];
      setIsOpenShowHideColumnsModal(false);
      await getTableFieldsOrder(originalTableFields);
    } else {
      setIsOpenShowHideColumnsModal(false);
    }
  }
  const searchInDateRange = () => {
    if (startDate && endDate) {
      let filterCriteria = {};
      let startDate = (moment(startDate).format(config.dateDayMonthFormat));
      let endDate = (moment(endDate).format(config.dateDayMonthFormat));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        {
          'key': 'date',
          'value': startDate,
          'type': 'gte'
        },
        {
          'key': 'date',
          'value': endDate,
          'type': 'eq'
        }];
      setFilterCriteria(filterCriteria);
      getDataFromServer(filterCriteria);
    } else {
      alert('no dates selected');
    }
  }

  const toggle = async (id) => {
    let opId = openId === id ? '' : id;
    setOpenId(opId);
  }

  const print = () => {
    window.print();
  }
  const toogleMultiSelect = async () => {
    setShowMultiSelectDropDown(!showMultiSelectDropDown);
  }
  const onGlobalSearchChange = async (e) => {
    let suggestions = suggestions;
    suggestions[0] = { "label": `Search By First Name ${e.query}` }
    setSuggestions(suggestions);
  }


  const getScreenHeader = () => {
    const { t } = props;
    return (

      <div className='pb-4'>

        <h4>
          <span className='postionRelative pt-2'>
            <a onClick={() => closeFormModal} className='heading'>
              {props.customDisplayHeader ? props.customDisplayHeader() : t(props.displayName)}
            </a>{isOpenFormModal && displayViewOfForm === 'screen' ? ` / ${formType} ` : null}
          </span>
        </h4>
        <div className="row">
          {/* global Search */}
          <div className='col-8'>
            {(props.dateSearchRequired) ?
              <div className='d-flex gap-2'>
                <RenderIntervalDatePickerField handleDateValueInParent={handleSearchDateInterval}>
                </RenderIntervalDatePickerField>&nbsp;
                <Button className="date-search col-sm-2 mb-0" color="primary" onClick={searchInDateRanges}>{t('dataTable.table_head.search')}</Button>
              </div>
              : null}
          </div>
        </div >
        <div className='row mt-3'>
          {/* global Search */}
          <div className='col-sm-12'>

          </div>

          <div className='col-md-12 d-flex justify-content-between align-items-center' style={{ height: '80%' }}>

            {props.filterRequired ? <span className='mr-3' ref={closeMultiSelectDropdown}>
              <span className='multiSelectDropDownCard '>
                <span onClick={toogleMultiSelect}>
                  {t('Filters')}
                </span>
                <span onClick={toogleMultiSelect}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className='ml-1'
                    color='grey'
                  />
                </span>
                {
                  showMultiSelectDropDown ?
                    <Card className=' itemsWarperCard' >
                      {
                        tablefieldsToShow && tablefieldsToShow.length > 0 ? tablefieldsToShow.map((item, index) => {
                          return (
                            item.displayInFilter && item.show && < div >
                              <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                              <ul className='pl-0'>
                                {
                                  item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                    return (
                                      <div className='multiSelectWrapperItems' onClick={() => changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                        <span className='chekcIconAdjust'>
                                          <Checkbox
                                            checked={optionsItem.checkIcon}>
                                          </Checkbox>
                                        </span>
                                        <span className='chekcIconAdjustLabel'>
                                          {t(optionsItem.label)}
                                        </span>
                                      </div>
                                    )
                                  }) : null
                                }
                              </ul>
                              <hr className='my-0'></hr>
                            </div>
                          )
                        }) : null
                      }
                    </Card>
                    : null}
              </span>

            </span> : null}
            <div className='d-flex'>
              {actionsTypes && actionsTypes.length > 0 ? <Dropdown
                style={{ minWidth: '10%', lineHeight: 1, marginTop: '2px' }}
                className='mr-3'
                name='actions'
                value={actions}
                disabled={selectedRows && selectedRows.length > 0 ? false : true}
                options={actionsTypes}
                placeholder={t('Actions')}
                onChange={(e) => onActionsChange(e, 'dropdownFilter')}
              /> : null}
              <div className='ps-2 flex flex-wrap justify-content-center gap-2'>
                {props.printRequried ?

                  <Button
                    raised
                    size="small"
                    onClick={() => print()}
                  >
                    <FontAwesomeIcon
                      icon='print'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Print")}

                    />
                  </Button>
                  : null}
                {props.exportRequired &&
                  <Button
                    size="small"
                    onClick={() => exportToCsvModal()}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title={t("Export To CSV")}
                      className='pl-1' size='lg' />
                  </Button>
                }
                {props.exportRequired &&
                  <CSVLink
                    data={exportData}
                    filename={`${props.type}.csv`}
                    className="hidden text-white"
                    ref={csvLinkRef}
                    target="_blank" >
                  </CSVLink>}
                {props.sample ?
                  <Button
                    size="small"
                    onClick={() => bulkUploadMoadal()}>
                    <FontAwesomeIcon
                      icon='upload'
                      size='lg'
                      data-toggle="tool-tip" title={t("Bulk Upload")}
                      onClick={() => bulkUploadMoadal()} />
                  </Button>
                  : null}
                {props.gridRequried ?
                  <div className="btn-group mb-0" role="group">
                    <Button
                      outlined={viewType === 'grid' ? false : true}
                      size="small"
                      style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                      onClick={() => changeViewType('grid')}
                      title={t("Grid View")}
                    >
                      <FontAwesomeIcon
                        icon='grip-horizontal'
                        size="sm"
                        data-toggle="tool-tip" 
                        onClick={() => changeViewType('grid')}
                      />
                    </Button>
                    <Button
                      size="small"
                      outlined={viewType === 'list' ? false : true}
                      style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                      onClick={() => changeViewType('list')}
                      title={t("List View")}
                    >
                      <FontAwesomeIcon
                        icon='list'
                        size='lg'
                        data-toggle="tool-tip" 
                        onClick={() => changeViewType('list')}
                      />
                    </Button>
                  </div> : null}

                {props.settingsRequired ?

                  <Button
                    size="small"
                    onClick={() => openShowHideColumnsModal()}
                  >
                    <FontAwesomeIcon
                      icon='cog'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Settings")}
                      onClick={() => openShowHideColumnsModal()}
                    />
                  </Button> : null}

                {/* Add button*/}
                {props.addRequired && props.type !== 'SharedFiles' ? displayViewOfForm === 'screen' ? 
                  <a href={`/create_${props.routeTo}`}  >
                    <Button size="small"
                      className='button-add'
                      onClick={() => openFormModal({}, 'add')}>
                      <FontAwesomeIcon
                        icon='plus'
                        size='lg'
                        data-toggle="tool-tip" title={t("Add")}
                      />
                    </Button>
                  </a>
                  :
                  <Button
                    size="small"
                    className='button-add'
                    title={props?.buttonToolTips?.add ?  t(props?.buttonToolTips?.add) : t("Add")}
                    onClick={() => openFormModal({}, 'add')}
                  >
                    <FontAwesomeIcon
                      icon='plus'
                      size='lg'
                      data-toggle="tool-tip"
                    />
                  </Button>
                  : null}
                {props.addRequired && props.type === 'SharedFiles' && props.query && props.query.size > 0 && props.folderPermission && props.folderPermission === 'Edit' ? displayViewOfForm === 'screen' ?
                  <a href={`/create_${props.routeTo}`}  >
                    <Button size="small"
                      className='button-add'
                      onClick={() => openFormModal({}, 'add')}>
                      <FontAwesomeIcon
                        icon='plus'
                        size='lg'
                        data-toggle="tool-tip" title={t("Add")}
                      />
                    </Button>
                  </a>
                  :
                  <Button
                    size="small"
                    className='button-add'
                    title={props?.buttonToolTips?.add ?  t(props?.buttonToolTips?.add) : t("Add")}
                    onClick={() => openFormModal({}, 'add')}
                  >
                    <FontAwesomeIcon
                      icon='plus'
                      size='lg'
                      data-toggle="tool-tip"
                    />
                  </Button>
                  : null}
                {/* priority  in Issues*/}
                {!isOpenFormModal &&

                  <Button
                    color="secondary"
                    size="small"
                    title={t("Refresh")}
                    onClick={() => getDataFromServer('', 'refresh')}>
                    <FontAwesomeIcon
                      icon='sync-alt'
                      size='lg'
                      data-toggle="tool-tip"
                      color={config.templateColor}
                      className='refreshIcon pl-1' />
                  </Button>}
                {(props.uploderequired) || (props.type === 'SharedFiles' && props.query && props.query.size > 0 && props.folderPermission && props.folderPermission === 'Edit') ?
                  <div title={'Upload Files'}>
                    {getFileUpload(-1,props.uploadDetails.getFormFields()[props.uploadDetails.getFormFields()?.findIndex( field => field.name === 'multiFile')])}
                  </div>
                  : null}

                {props.fileuplode  ?
                  getFileUplodeModal()
                  : null}
              </div>
            </div>
            {props.sawppingFolders ?
              <div className="btn-group mb-0" role="group">
                <Button
                  outlined={dataLoadType === 'files' ? false : true}
                  size="small"
                  style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                  onClick={() => changeRecords('files')}
                >
                  <FontAwesomeIcon
                    icon='file'
                    size='lg'
                    data-toggle="tool-tip"
                  />&nbsp;
                  Files
                </Button>
                <Button
                  size="small"
                  outlined={dataLoadType === 'folders' ? false : true}
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  onClick={() => changeRecords('folders')}
                >
                  <FontAwesomeIcon
                    icon='folder'
                    size='lg'
                    data-toggle="tool-tip"
                  />&nbsp;
                  Folders
                </Button>
              </div> : null}
              <div className='col-4'>
            {
              // !isOpenFormModal && 
              props.globalSearch ?
                <div className='serachAlignment'>
                  <div className="p-inputgroup float-right">
                    <span className="p-inputgroup-addon">
                      < i className="pi pi-search" ></i >
                    </span>
                    <InputText
                      type="search"
                      name='globalSearch'
                      id='globalSearch'
                      value={globalSearch}
                      onChange={(e) => onFilterChange(e)}
                      placeholder={props.globalSearch ? (props.globalSearch) : 'search'}
                      size="25" />
                  </div>
                </div>
                : null
            }
          </div>
          </div>

          {false &&
            <div className='col-md-12 flex-wrap justify-content-end align-items-center gap-2 mt-2'>
              <FilterOperations
                saveCriteria={saveCriteria}
                getDataFromServer={getDataFromServer}
                screenName={props.type}
                defaultCriteria={props.defaultCriteria}
                getCriteriaOfSavedFilt={getCriteriaOfSavedFilt}
              />

            </div>
          }

          <div className='col-12  pb-2 mt-4 d-none'>
            <span className='float-right pt-2'>
              <div className='col-12 px-0 pb-1'>
                {config.paginationPosition == 'top' ? getPaginator() : null}
              </div>
            </span>
            <span className='pt-2'>
              {/* Export to csv */}
              <span className='float-left pt-2' >
                {props.filterRequired ? <span className='mr-3' ref={closeMultiSelectDropdown}>
                  <span className='multiSelectDropDownCard '>
                    <span onClick={toogleMultiSelect}>
                      {t('Filters')}
                    </span>
                    <span onClick={toogleMultiSelect}>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className='ml-1'
                        color='grey'
                      />
                    </span>
                    {
                      showMultiSelectDropDown ?
                        <Card className=' itemsWarperCard' >
                          {
                            tablefieldsToShow && tablefieldsToShow.length > 0 ? tablefieldsToShow.map((item, index) => {
                              return (
                                item.displayInFilter && item.show && < div >
                                  <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                                  <ul className='pl-0'>
                                    {
                                      item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                        return (
                                          <div className='multiSelectWrapperItems' onClick={() => changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                            <span className='chekcIconAdjust'>
                                              <Checkbox
                                                checked={optionsItem.checkIcon}>
                                              </Checkbox>
                                            </span>
                                            <span className='chekcIconAdjustLabel'>
                                              {t(optionsItem.label)}
                                            </span>
                                          </div>
                                        )
                                      }) : null
                                    }
                                  </ul>
                                  <hr className='my-0'></hr>
                                </div>
                              )
                            }) : null
                          }
                        </Card>
                        : null}
                  </span>

                </span> : null}

                {!isOpenFormModal && actionsTypes && actionsTypes.length > 0 ? <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1.3, marginTop: '2px' }}
                  className='mr-3'
                  name='actions'
                  value={actions}
                  disabled={selectedRows && selectedRows.length > 0 ? false : true}
                  options={actionsTypes}
                  placeholder={t('Actions')}
                  onChange={(e) => onActionsChange(e, 'dropdownFilter')}
                /> : null}
                <div className='flex flex-wrap justify-content-center gap-3'>
                  {!isOpenFormModal && props.printRequried ?

                    <Button
                      raised
                      size="sm"
                      onClick={() => print()}
                    >
                      <FontAwesomeIcon
                        icon='print'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("Print")}
                        onClick={() => print()}
                      />
                    </Button>

                    : null}
                  {!isOpenFormModal && props.exportRequired && <Button color="primary"
                    className='p-1 ml-auto mb-0 mt-1'
                    size={'sm'} onClick={getDataToExport}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title={t("Export To CSV")}
                      className='pl-1' size='lg' />
                  </Button>}
                  {!isOpenFormModal && props.exportRequired && <CSVLink
                    data={exportData}
                    filename={`${props.type}.csv`}
                    className="hidden text-white"
                    // ref={(r) => csvLinkRef = r}
                    target="_blank" >
                  </CSVLink>}
                  {!isOpenFormModal && props.sample ?
                    <Button color="primary"
                      size="sm"
                      className="p-1 mt-1 mb-0"
                      onClick={() => bulkUploadMoadal()}>
                      <FontAwesomeIcon
                        icon='upload'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("Bulk Upload")}
                        onClick={() => bulkUploadMoadal()} />
                    </Button>
                    : null}

                  {!isOpenFormModal && props.gridRequried ? <ButtonGroup className='mb-0 mr-3'>
                    <Button color="primary"
                      outline={viewType === 'grid' ? false : true}
                      size="sm"
                      className="p-1 ml-auto mt-1 mb-0"
                      onClick={() => changeViewType('grid')}
                    >
                      <FontAwesomeIcon
                        icon='grip-horizontal'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("Grid")}
                        onClick={() => changeViewType('grid')}
                      />
                    </Button>
                    <Button color="primary"
                      size="sm"
                      outline={viewType === 'list' ? false : true}
                      className="p-1 ml-auto mt-1 mb-0"
                      onClick={() => changeViewType('list')}
                    >
                      <FontAwesomeIcon
                        icon='list'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("List")}
                        onClick={() => changeViewType('list')}
                      />
                    </Button>
                  </ButtonGroup> : null}

                  {!isOpenFormModal && props.settingsRequired ?
                    <span> &nbsp;
                      <Button color="primary"
                        size="sm"
                        className="p-1 ml-auto mt-1 mb-0"
                        onClick={() => openShowHideColumnsModal()}
                      >
                        <FontAwesomeIcon
                          icon='cog'
                          className='pl-1' size='lg'
                          data-toggle="tool-tip" title={t("Settings")}
                          onClick={() => openShowHideColumnsModal()}
                        />
                      </Button> </span> : null}

                  {/* Add button */}
                  {props.addRequired && !isOpenFormModal ?
                    <a href={`/create_${props.routeTo}`}  >
                      <Button color="primary"
                        size="sm"
                        className="p-1 ml-auto mt-1 mb-0"
                      >
                        <FontAwesomeIcon
                          icon='plus'
                          className='pl-1' size='lg'
                          data-toggle="tool-tip" title={t("Add")}
                        />
                      </Button>
                    </a>
                    : null}

                  {/* priority  in Issues*/}
                  {!isOpenFormModal &&
                    <span> &nbsp;
                      <Button
                        color="secondary"
                        size="sm"
                        className={props.addRequired ? "p-1 mt-1 mb-0" : 'p-1 ml-auto mt-1 mb-0'}
                        onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
                        <FontAwesomeIcon
                          icon='sync-alt'
                          size='lg'
                          data-toggle="tool-tip" title={t("Refresh")}
                          color={config.templateColor}
                          className='refreshIcon pl-1' />
                      </Button> </span>}
                </div>
              </span>
            </span>
          </div>
        </div>

        {props.paginatorRequired && config.paginationPosition == 'top' ?
          <div className='row mt-3'>
            <span className='col-md-6'></span>
            <span className='col-md-6'>{config.paginationPosition == 'top' ? getPaginator() : null}</span>
          </div>
          : null}
      </div >
    )
  }
  const onGlobalSerachFilterChange = async (event) => {
    if (event) {
      await onFilterChange(event)
    }

  }

  const getCardHeader = () => {
    return (
      // <CardHeader className='cardHeader'>
      <div className="row m-0">
        {/* table name */}
        <div className='col-3 pl-0 pt-2'>
          <h4><b>{props.type}{props.type == 'Activiti' ? 'es' : ''}</b></h4>
        </div>
        <span className='col-9 text-right'>
          <Row>
            {/* Export to csv */}
            {/* <Button color="primary"
                    className='p-1 ml-auto'
                    size={'sm'} onClick={getDataToExport}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title="Export To CSV"
                      className='pl-1' size='lg' />
                  </Button>
                  <CSVLink
                    data={exportData}
                    filename={`${props.type}.csv`}
                    className="hidden text-white"
                    ref={(r) => csvLinkRef = r}
                    target="_blank" >
                  </CSVLink> */}
            {/* {props.sample ?
              <Button color="primary"
                size="sm"
                className="p-1"
                onClick={bulkUploadMoadal}>
                <FontAwesomeIcon
                  icon='upload'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Bulk Upload"
                  onClick={bulkUploadMoadal} />
              </Button>
              : null} */}
            {/* Add Settings Button */}
            {props.settingsRequired ?
              <Button
                raised
                size="sm"
                className="p-1 ml-auto mt-1"
                onClick={() => openShowHideColumnsModal()}
              >
                <FontAwesomeIcon
                  icon='cog'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Settings"
                  onClick={() => openShowHideColumnsModal()}
                />
              </Button>
              : null}
            {/* Add button */}
            {props.addRequired ?
              <a href={`/create_${props.routeTo}`} >
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto"
                // onClick={() => openFormModal('add')}
                >
                  <FontAwesomeIcon
                    icon='plus'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Add"
                  //  onClick={() => openFormModal('add')}
                  />
                </Button></a>
              : null}
            {/* priority  in Issues*/}
            <Button
              color="secondary"
              size="sm"
              // className="p-1 ml-auto"
              className={props.addRequired ? "p-1" : 'p-1 ml-auto'}
              onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
              <FontAwesomeIcon
                icon='sync-alt'
                size='lg'
                data-toggle="tool-tip" title="Refresh"
                color={config.templateColor}
                className='refreshIcon pl-1' />
            </Button>
          </Row>
        </span>

      </div>
      // </CardHeader>
    )
  }
  const cancelUserPwdResetModal = async () => {
    setOpenUserPasswordResetModal(false);
    setActions('');
  }
  const handleSearchDateInterval = (startDate, endDate) => {
    if (startDate) {
      setStartDate(startDate);
    }
    if (endDate) {
      setEndDate(endDate);
    }
  }

  const searchInDateRanges = () => {
    if (startDate && endDate) {
      let filterCriteria = {};
      let startDate = (moment(startDate).format('YYYY-MM-DD'));
      let endDate = (moment(endDate).format('YYYY-MM-DD'));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        { 'key': searchInDateRangeField, 'value': startDate, 'type': 'gte' },
        { 'key': searchInDateRangeField, 'value': endDate, 'type': 'lte' }];
      getDataFromServer(filterCriteria);
    }
    else {
      alert('no dates selected');
    }
  }

  const getColumns = (e, d , type) => {
    const { t } = props
    // const self = this;
    // self.e = e;
    // self.d = d;
    let tableFields = type && type === 'secondaryTable' ? props?.secondaryTableFields() : type && type === 'expandtable' ? props?.expandTableFields() : tablefieldsToShow;
    if (tableFields && tableFields.length > 0) {
      return tableFields.map((item, i) => {
        if (item.type == "relateAutoComplete") item.filter = true;
        let column = (item.show &&
          <Column key={item.field + i}
            style={{
              maxwidth: item.width,

            }}
            bodyStyle={item.capitalizeTableText ? {
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textTransform: 'capitalize',
              textAlign: item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign,
              maxWidth : item.width ? item.width : 'unset',...item?.style ,
            } : {
              textOverflow: 'ellipsis',
              overflow: item.field == 'Actions' ? 'visible':'hidden',
              whiteSpace: 'nowrap',
              textAlign: item.field == 'Actions' ||  item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign,
              maxWidth : item.width ? item.width : 'unset',...item?.style ,
            }}
            field={item.field}
            editRequired={e}
            deleteRequired={d}
            header={t(item.header)}
            changeFieldName={item.changeFieldName}
            body={(item, column,index ) =>changeFieldValues(item, column, type)}
            headerStyle={{
              padding: 4, fontWeight: 500, width: item.width, fontSize: 13,
              // color: config.whiteColor, backgroundColor: config.templateColor
            }}
            filter={item.filter ? item.filter : false}
            dataType={item.type == "date" ? "date" : item.type == "number" ? "numeric" : "text"}
            showFilterMatchModes={item.type == "dropDown" || item.type == "radio" ? false : true}
            showClearButton={true}
            filterClear={filterClearTemplate}
            showApplyButton={(item.type == "dropDown" || item.type == 'date' || item.type == 'radio') ? false : true}
            filterMatchMode={(item.type == "dropDown" || item.type == 'date' || item.type == 'radio') ? '' : "contains"}
            // showFilterMenu={false}
            sortable={item.sortable ? true : false}
            // filterPlaceholder={item.placeholder ? item.placeholder : 'search'}            
            filterElement={item.filterElement ?
              multiSelectFilterTemplate


              : item["type"] == "date" ?
                dateFilterTemplate

                : null
            }
            selectionMode={item.selectionMode}
          />)
        return column;
      })
    }

  }



  const filterClearTemplate = (options) => {
    return (<button aria-label="Clear" type="button" className="p-button p-component p-button-outlined p-button-sm"
      onClick={(e) => filterClearCallback(e, options)} style={{ margin: '3px' }}>
      <span class="p-button-label p-c">Clear</span>
    </button>);
  };

  const filterClearCallback = (e, options) => {
    getDataFromServer(saveCriteria, 'clearFilter', options);
    const element = document.querySelector('.p-column-filter-overlay.p-component.p-fluid.p-column-filter-overlay-menu.p-ripple-disabled.p-connected-overlay-enter-done');
    if (element) {
      element.style.display = 'none';
    }
  }

  const multiSelectFilterTemplate = (options) => {
    let filterOptions = [];
    filterOptions = getMultiSelectOptions(options);
    return <MultiSelect value={options.value} options={filterOptions} maxSelectedLabels={1} optionallabel="label"
      onChange={(e) => options.filterApplyCallback(e.value, options.index)} placeholder="Any"
      onHide={() => {
        if (options.value && options.value.length > 0) {
          highlightFliter(options.field)
        }
      }} />;
  };

  const getMultiSelectOptions = (options) => {
    let tableFields = props.getTableFields();
    let i = tableFields.findIndex((col) => col.field === options.field);
    if (i > -1 && tableFields[i].filterElement) {
      return tableFields[i].filterElement;
    } else {
      return [];
    }
  }

  const dateFilterTemplate = (options) => {
    return <Calendar value={options.value} showButtonBar selectionMode="range"
      onChange={(e) => options.filterApplyCallback(e.value, options.index)}
      onHide={() => {
        if (options.value && options.value.length > 0) {
          highlightFliter(options.field)
        }
      }} />;
  };

  const allowExpansion = (rowData) => {
    return rowData.matches?.length > 0;
};
  const rowExpansionTemplate = (data) => {
    return (
        <div className="p-3">
            <DataTable
              rowClassName={rowClassName}
              value={data.matches}
              totalRecords={data?.matches?.length}
              paginator={false}
              lazy={true}
              size="small"
              resizableColumns={true}
              columnResizeMode="expand"
              emptyMessage={configMessages.noRecords}
              metaKeySelection={false}
              loading={isLoading}
              style={data?.matches && data?.matches?.length == 0 ?
                { textAlign: 'center' }
                : null}
            >
              {getColumns(props.editRequried, props.deleteRequried, 'expandtable')}
            </DataTable>
            
        </div>
    );
};

  const getDataTable = () => {
    return (
      <div ref={tableRef}>
        <DataTable
        rowClassName={rowClassName}
        value={allUsersData}
        totalRecords={totalRecordsLength}
        paginator={false}
        lazy={true}
        size="small"
        resizableColumns={true}
        columnResizeMode="expand"
        onSort={sortChange}
        sortField={sortField} 
        sortOrder={sortOrder}
        onFilter={onFilterColumns}
        scrollable={true}
        onSelectionChange={e => onSelectRowsUpdate(e)}
        scrollHeight='68vh'
        emptyMessage={configMessages.noRecords}
        selection={selectedRows}
        onScrollCapture={onVirtualScroll}
        selectionMode={'radiobutton'}
        metaKeySelection={false}
        loading={isLoading}
        style={allUsersData && allUsersData.length == 0 ?
          { textAlign: 'center' }
          : null}
      >
        {getColumns(props.editRequried, props.deleteRequried)}
      </DataTable>
      </div>
    )
  }

  const getContentFilesTable = () => {
    let contentSearchedFiles = serverResponse?.contentSearchDocs
    if(contentSearchedFiles?.length > 0) {
      contentSearchedFiles = contentSearchedFiles.map( _index =>  { 
        _index.type = 'pdf'
        return _index
      })
    }

    return<>
      {serverResponse?.contentSearchDocs?.length > 0 && 
      <div className='mt-2'>
        <DataTable
        rowClassName={rowClassName}
        value={contentSearchedFiles}
        totalRecords={contentSearchedFiles?.length}
        paginator={false}
        lazy={true}
        size="small"
        resizableColumns={true}
        columnResizeMode="expand"
        onSort={sortChange}
        sortField={sortField} 
        sortOrder={sortOrder}
        onFilter={onFilterColumns}
        scrollable={true}
        scrollHeight='68vh'
        emptyMessage={configMessages.noRecords}
        metaKeySelection={false}
        loading={isLoading}
        expandedRows={expandedRows} 
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        style={allUsersData && allUsersData.length == 0 ?
          { textAlign: 'center' }
          : null}
      >
        <Column expander={allowExpansion} style={{ width: '2rem' }} />
        {getColumns(props.editRequried, props.deleteRequried, 'secondaryTable')}
      </DataTable>
    </div>
    }
    </>

  }

  const getDropzoneDataTable = () => {
    return(
      <>
            <Dropzone
              onRef = { (ref) => dropZoneRef.current = ref}
                renderData={getDataTable}
                type={props.type}
                query={props.query}
                folderPermission={props.folderPermission}
                displayName={props.displayName}
                closeDeleteModal={closeDeleteModal}
                assingValueToForm={props.assingValueToForm}
                getDataFromServer={getDataFromServer}
                viewType={'Table'}
                openSidePanel={openSidePanel}
                isLoading={() => noData && !isLoading}
                closeSidePanel={closeSidePanel}
                isSidePanelLoading={isSidePanelLoading}
                sidePanelDetails={sidePanelDetails}
                dataType={sidePanelType}
                filterCriteria={filterCriteria}
                contentSearchedFiles ={serverResponse?.contentSearchDocs}
              />
          </>
    )
  }

  const getTabInfo = () => {
    return null;
  }

  const getPaginator = () => {

    return (
      <PaginatorComponent
        totalRecords={totalRecordsLength}
        first={first}
        rows={rows}
        onPageChange={onPageChange}
        isWeb={true}
      />
    )
  }


  // Function to handle drag start
  const onDragStart = (e, item) => {
    setDraggedItem(item);
  };

  // Function to handle drag over
  const onDragOver = (e) => {
    e.preventDefault();
  };

  // Function to handle drag enter
  const onDragEnter = (e) => {
    e.preventDefault();
  };

  // Function to handle drag leave
  const onDragLeave = (e) => {
    e.preventDefault();
  };

  // Function to handle drop

  const onDrop = (e, targetFolder) => {
    e.preventDefault();
    if (draggedItem) {
      if (draggedItem) {
        const url = draggedItem.type
          ? `files/fileMove/${draggedItem._id}`
          : `folders/moveFolder/${draggedItem._id}`;

        const body = {
          ...draggedItem,
          destParentFolderId: targetFolder._id,
          destType: targetFolder.type,
          destPath: targetFolder.path,
        };
        fetchMethodRequest("PUT", url, body)
          .then(async (res) => {
            if (res && res.respMessage) {
              showToasterMessage(res.respMessage, 'success');
              closeDeleteModal();
              getDataFromServer();
            } else {
              showToasterMessage(res.errorMessage, 'error');
            }
          })
          .catch((err) => {
            console.error("Fetch error:", err);
            showToasterMessage('Error moving files', 'error');
          });
      } else {
        console.error("Invalid draggedItem:", draggedItem);
        showToasterMessage('Error: Invalid item dragged', 'error');
      }

      setDraggedItem(null);
    }
  };

  const getContentFiles = (data,type) => {
    let tableFields = type && type === 'contentFiles' ? props?.secondaryTableFields() : tablefieldsToShow;
    return data.map((item, i) => (
      <div className={'col-sm-6 col-md-3 px-1 mt-3' + (openSidePanel ? ' col-lg-6' : ' col-lg-4')}
        key={i}>
        <Card className={(props.type === 'SharedFiles' ? sidePanelDetails?.fileId && sidePanelDetails?.fileId === item?.fileId?._id ? 'active_summary_file' : '' : sidePanelDetails?.fileId && item?._id === sidePanelDetails?.fileId ? 'active_summary_file' : '') +' folder-box min-height-10rem'}>
          <CardBody className='tableCardBody text-align-left p-0 row'>
            {tableFields && tableFields.length > 0 ? (
              tableFields.map((elememt, index) => (
                <div 
                  className={
                    elememt.displayInSettings === true ? elememt.customWidth ? elememt.customWidth : `col-12` : 'd-none'
                  }
                  key={index}
                  title={elememt.field === 'name' ? item[elememt.field] : ''}
                  >
                  <div className='overflow-x-hidden d-flex align-items-center'>
                    { elememt.showIcon ? (
                      <div className='w-100 align-items-center'>
                          { elememt.fileViewType === 'view' && (
                            <div className='textElipsess position-absolute bottom-0'>
                              <a href={`${config.imgUrl}${props.type === 'SharedFiles' ? item?.fileId?.path  : item?.path}`}
                              target="_blank"
                              >
                                <span
                                  className='font-size-12'
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {item[elememt.field]}
                                </span>
                              </a>
                            </div>
                          )}                       
                      </div>
                    ): elememt.fieldType === 'customType' ? (
                      <span style={elememt.style} className='subFields' >
                        {customChangeFieldValues(item , elememt)}
                      </span>
                    )
                    :(
                      <div className="subFields" style={elememt.style} title={item[elememt.field] ? item[elememt.field] : ''  }>
                        <span>{item[elememt.field]}</span>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : null}
          </CardBody>
        </Card>
      </div>
    ));
  };

  const renderData = (data) => {
    return data.map((item, i) => (
      <div className={'col-sm-6 col-md-3 px-1 mt-3' + (openSidePanel ? ' col-lg-3' : ' col-lg-2')}
        key={i}
        draggable='true'
        onDragStart={(e) => onDragStart(e, item)}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={(e) => onDrop(e, item)}>
        <Card className={(props.type === 'SharedFiles' ? sidePanelDetails?.fileId && sidePanelDetails?.fileId === item?.fileId?._id ? 'active_summary_file' : '' : sidePanelDetails?.fileId && item?._id === sidePanelDetails?.fileId ? 'active_summary_file' : '') +' folder-box'}>
          <CardBody className='tableCardBody text-align-left p-0 row'>
          <div className='position-relative'>
              <div className='options-icon' 
              title='Options'
              onClick={(e) => handleShowOptions(e,item)}>
                <FontAwesomeIcon
                        className='genderIconAlignment getcursor summarize-icon default-icon-color width-15 margin-right-7'
                        color='white'
                        icon={faEllipsisV}
                        data-toggle='tool-tip'
                      />
              </div>
              {activeOptionId && activeOptionId === item?._id && <ul ref={menuRef} className={`dropdown-menu d-flex flex-column justify-content-start options-menu ${openUpward}`}>
            {(item.type) ?
                  <li className='dropdown-item'
                    title='Ask a Question'
                    onClick={() => askAQuestion(item)}
                  >
                    <FontAwesomeIcon
                    className='genderIconAlignment getcursor summarize-icon default-icon-color width-15 me-2'
                    color='white'
                    icon={faQuestionCircle}
                    data-toggle='tool-tip'   
                  />
                  Ask a Question
                  </li>
                  : null}

              {(item.type) ?
                  <li className='dropdown-item'
                    title='Summarize Document'
                    onClick={() => summarizeAFile(item)}
                  >
                    <FontAwesomeIcon
                    className='genderIconAlignment getcursor summarize-icon default-icon-color width-15 me-2'
                    color='white'
                    icon={faClipboardList}
                    data-toggle='tool-tip'
                  />
                  Summarize a File
                  </li>
                  : null}
                {(props.type === 'SharedFiles' && item.permissions === 'Edit' && props.shareRequired) || (props.displayName === 'Home' && props.shareRequired) ?
                  <li className='dropdown-item'
                    title='share'
                    onClick={() => sharedFiles(item, 'share', item.type, filterCriteria)}
                  >
                    <FontAwesomeIcon
                    className='genderIconAlignment getcursor default-icon-color width-15 me-2'
                    color='white'
                    icon='user-plus'
                    data-toggle='tool-tip'
                  />
                  Share
                  </li>
                  : null}
                {props.moveRequired && (
                 <li className='dropdown-item'
                  title='Move'
                  onClick={() => moveFolders(item)}
                 >
                   <FontAwesomeIcon
                    className='genderIconAlignment align_move getcursor default-icon-color me-2'
                    icon='file-export'
                    data-toggle='tool-tip'
                  />
                  Move
                 </li>
                )}
                {props.preview && (
                  <li className='dropdown-item'
                  title='Preview'
                  onClick={() => openPreviewModal(item)}
                  >
                    <FontAwesomeIcon
                    className='genderIconAlignment getcursor width-15 margin-right-9'
                    color='#17a2b8'
                    icon='file'
                    data-toggle='tool-tip'
                    style={{
                      color: '#17a2b8'
                    }}
                  />
                  </li>
                )}
                {props.editRequired && (
                 <li className='dropdown-item'
                    title='Edit'
                    onClick={() => {
                      openFormModal(item, 'edit', !item.type ? 'folders' : 'files');
                    }}
                 >
                   <FontAwesomeIcon
                    className='genderIconAlignment getcursor default-icon-color width-15 margin-right-9'
                    color='white'
                    icon='pen'
                    data-toggle='tool-tip'
                  />
                  Edit
                 </li>
                )}
                {props.deleteRequired && (
                  <li className='dropdown-item'
                    title='Delete'
                    onClick={() => setDeleteRecords(item, 'Delete', item.type)}
                  >
                    <FontAwesomeIcon
                    className='genderIconAlignment getcursor me-2'
                    color='white'
                    icon='trash'
                    data-toggle='tool-tip'
                    style={{
                      color: '#bf1725',
                      width: '13',
                    }}
                  />
                  Delete
                  </li>
                )}
                </ul>}
            </div>
            {tablefieldsToShow && tablefieldsToShow.length > 0 ? (
              tablefieldsToShow.map((elememt, index) => (
                <div 
                  className={
                    elememt.displayInSettings === true ? elememt.customWidth ? elememt.customWidth : `col-12` : 'd-none'
                  }
                  key={index}
                  title={elememt.field === 'name' ? item[elememt.field] : ''}
                  >
                  <div className='overflow-x-hidden d-flex align-items-center'>
                  {elememt.designIcon && item[elememt.field] && 
                      <FontAwesomeIcon
                      className='genderIconAlignment align_move mr-3px default-cursor'
                      style={{
                        color: '#999999',
                      }}
                      icon={elememt.designIcon}
                    />
                    }
                    {elememt.fieldType === 'Link' ? (
                      <div className='d-flex'>
                        <span
                          style={elememt.style}
                          onClick={() => openViewModal(item, 'view')}
                        >
                          <b>{item[elememt.field]}</b>
                        </span>
                      </div>
                    ) : elememt.showIcon ? (
                      <div className='w-100 align-items-center'>
                        {elememt.showIcon &&
                          <div>
                            { !item.type && elememt.fieldType === 'redirectable' &&
                              <NavLink
                              to={`/${elememt.redirecTo}?searchId=${item._id}&searchName=${elememt.redirectFilterField}`}
                            >
                                  <FontAwesomeIcon
                                    icon={'folder'}
                                    className='pl-2 iconsize getcursor'
                                    size='2x'
                                    data-toggle='tool-tip'
                                    title={props.type === 'SharedFiles' ? (item?.fileId?.name || item?.folderId?.name) : item.name}
                                />
                            </NavLink>
                            }
                            { item.type && item.type !== 'docx' && elememt.fileViewType === 'view' &&
                                <a href={`${config.imgUrl}${props.type === 'SharedFiles' ? item?.fileId?.path  : item?.path}`} target="_blank">
                                    <FontAwesomeIcon
                                      icon={!item.type ? 'folder' : item.type === 'pdf' ? 'file-pdf' : 'file'}
                                      className='pl-2 iconsize getcursor'
                                      size='2x'
                                      style={{ color: item.type && item.type === 'pdf' ? 'brown' : 'inherit' }}
                                      data-toggle='tool-tip'
                                      title={props.type === 'SharedFiles' ? (item?.fileId?.name || item?.folderId?.name) : item.name}
                                  />
                                </a>
                            }
                            { item.type && item.type === 'docx' &&  elememt.fileViewType === 'view' &&
                               <a href={`https://docs.google.com/viewer?url=${config.imgUrl}${item?.path}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon
                                      icon={'file-word'}
                                      className='pl-2 iconsize getcursor'
                                      size='2x'
                                      style={{ color: '#1292ee'}}
                                      data-toggle='tool-tip'
                                      title={props.type === 'SharedFiles' ? (item?.fileId?.name || item?.folderId?.name) : item.name}
                                  />
                              </a>

                            }
                          </div>
                        }
                        {!item.type && elememt.fieldType === 'redirectable' && (
                          <div className='textElipsess'>
                            <span title={props.type === 'SharedFiles' ? (item?.type ? item?.fileId?.name : item?.folderId?.name) : item[elememt.field]}>
                              <NavLink
                                to={`/${elememt.redirecTo}?searchId=${item._id}&searchName=${elememt.redirectFilterField}`}
                              >
                                <span
                                  className='font-size-16'
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {props.type === 'SharedFiles' ? (item?.type ? item?.fileId?.name : item?.folderId?.name) : item[elememt.field]}
                                </span>
                              </NavLink>
                            </span>
                          </div>
                        )}
                          {/* //by using the link */}
                          {item.type && item.type !== 'docx' && elememt.fileViewType === 'view' && (
                            <div className='textElipsess'>
                              <a href={`${config.imgUrl}${props.type === 'SharedFiles' ? item?.fileId?.path  : item?.path}`}
                              target="_blank"
                              >
                                <span
                                  className='font-size-16'
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {props.type === 'SharedFiles' ? (item?.fileId?.name || item?.folderId?.name) : item[elememt.field]}
                                </span>
                              </a>
                            </div>
                          )}                       
                          {item.type && item.type === 'docx' &&  elememt.fileViewType === 'view' && (
                          <div className='textElipsess'>
                            <a
                              href={`https://docs.google.com/viewer?url=${config.imgUrl}${item?.path}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span
                                className='font-size-16'
                                style={{ fontWeight: 'bold' }}
                              >
                                {props.type === 'SharedFiles' ? (item?.fileId?.name || item?.folderId?.name) : item[elememt.field]}
                              </span>
                            </a>
                          </div>
                        )}
                      </div>
                    ) : elememt.fieldType === 'Badge' ? (
                      <div style={elememt.style}>
                        <Badge
                          color={
                            item.status === 'Active'
                              ? 'success'
                              : item.status === 'Inactive'
                                ? 'warning'
                                : item.status === 'Pending'
                                  ? 'danger'
                                  : item.status === 'Reject'
                                    ? 'error'
                                    : item.status === 'Completed'
                                      ? 'primary'
                                      : 'info'
                          }
                        >
                          {item[elememt.field]}
                        </Badge>
                      </div>
                    ) : elememt.fieldType === 'Role' ? (
                      <div style={elememt.style}>
                        <Badge pill color='success'>
                          {item[elememt.field]}
                        </Badge>
                      </div>
                    ) : elememt.fieldType === 'Date' ? (
                      <div className="subsubFields" title={ item[elememt.field] ?
                        dateFormats.formatDate(
                         item[elememt.field],
                          config.dateDayMonthFormat
                        ) : null} >
                        { item[elememt.field] ?
                        dateFormats.formatDate(
                         item[elememt.field],
                          config.dateDayMonthFormat
                        ) : null}
                      </div>
                    ) : elememt.fieldType === 'Time' ? (
                      <div>
                        {dateFormats.formatDate(
                          item[elememt.field],
                          config.timeFormat
                        )}{' '}
                        - $
                        {dateFormats.formatDate(
                          item['toTime'],
                          config.timeFormat
                        )}
                      </div>
                    ) : elememt.fieldType === 'Array' ? (
                      <span style={elememt.style}>
                        {flattenArray(item[elememt.field])}
                      </span>
                    ) : !elememt.isMultiSelect &&
                      elememt.fieldType === 'dropDown' ? (
                      <div style={elememt.style}>
                        {getBadgeData(elememt, item[elememt.field])}
                      </div>
                    ) : elememt.isMultiSelect &&
                      elememt.fieldType === 'dropDown' ? (
                      <div style={elememt.style}>
                        {getMultiSelectBadge(elememt, item[elememt.field])}
                      </div>
                    ) : elememt.fieldType === 'relateAutoComplete' ? (
                      <span style={elememt.style}>
                        {item[elememt.field] &&
                          elememt.searchField &&
                          item[elememt.field][elememt.searchField]
                          ? item[elememt.field][elememt.searchField]
                          : null}
                      </span>
                    ) : elememt.fieldType === 'customType' ? (
                      <span style={elememt.style} className='subFields' title={item[elememt.field] ? item[elememt.field] : ''  }>
                        {customChangeFieldValues(item , elememt)}
                      </span>
                    ) :
                    (
                      <div className="subFields" style={elememt.style} title={item[elememt.field] ? item[elememt.field] : ''  }>
                        <span>{item[elememt.field]}</span>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : null}
          </CardBody>
        </Card>
      </div>
    ));
  };

  const getGridView = () => {
    // Filter folders and files
    const folders = allUsersData.filter((item) => !item.type);
    const files = allUsersData.filter((item) => item.type);
    let contentSearchedFiles = serverResponse?.contentSearchDocs ? serverResponse?.contentSearchDocs : []
    if(contentSearchedFiles?.length > 0) {
      contentSearchedFiles = contentSearchedFiles.map( _index =>  {
        _index.type = 'pdf'
        return _index
      })
    }

    return (
      <div className=' row ml-lg-0 ps-3'>
        <Loader loader={isLoading} />
        <>
            <Dropzone
              onRef = { (ref) => dropZoneRef.current = ref}
              folders={folders}
                files={files}
                noData={noData}
                contentSearchedFiles={contentSearchedFiles}
                renderData={renderData}
                // accept=".pdf, .docx"
                type={props.type}
                query={props.query}
                folderPermission={props.folderPermission}
                displayName={props.displayName}
                // rowData={rowData}
                closeDeleteModal={closeDeleteModal}
                assingValueToForm={props.assingValueToForm}
                getDataFromServer={getDataFromServer}
                viewType={'Grid'}
                isLoading={() => noData && !isLoading}
                openSidePanel={openSidePanel}
                sidePanelDetails={sidePanelDetails}
                closeSidePanel={closeSidePanel}
                isSidePanelLoading={isSidePanelLoading}
                dataType={sidePanelType}
                getContentFiles={getContentFiles}
                filterCriteria={filterCriteria}
                allUsersData={allUsersData}
                totalRecords={totalRecords}
                setFilterCriteria={setFilterCriteria}
                currentPageRef={currentPageRef}
                numberOfFoldersRef={numberOfFoldersRef}
                folderLoading={folderLoading}
              />
          </>
      </div>
    );
  };


  const getBadgeData = (element, value) => {
    let mcolor = getColorFromOptions(element.options, value);
    return (<Badge color={mcolor} pill >{value}</Badge>)
  }

  const getMultiSelectBadge = (element, value) => {
    let mcolor = getColorFromOptions(element.options, value);
    return (
      <div>
        {value.map((badge, index) => (
          <Badge key={index} color={mcolor} pill style={{ margin: '1px' }}>
            {badge}
          </Badge>
        ))}
      </div>
    )
  }

  const getTotalCard = () => {
    if (viewType === 'list' && displayViewOfForm != "sideForm") {
      return (
        <Card className='cardForListMargin' >
          <CardBody className='tableCardBody'>
            {!isOpenFormModal && displayViewOfForm === 'screen' ? getScreenHeader() : displayViewOfForm === 'modal' ? getScreenHeader() : null}
            {getTabInfo()}
            {!isOpenFormModal && displayViewOfForm === 'screen'
              ? getDropzoneDataTable() : displayViewOfForm === 'modal' ? getDropzoneDataTable() : null}
              {getContentFilesTable()}
            {isOpenFormModal ? getFormModal() : null}
            {!isOpenFormModal && (displayViewOfForm === 'screen' || displayViewOfForm === 'modal') ? (config.paginationPosition == 'bottom' ? getPaginator() : null) : null}          </CardBody>
        </Card>
      )
    }
    if (viewType === 'list') {
      if (displayViewOfForm == "sideForm" && props.sideFormLeftOrRight == "right") {
        return (
          <Card className='cardForListMargin' >
            <CardBody className='tableCardBody'>
              {!isOpenFormModal ? getScreenHeader() : null}
              {getTabInfo()}
              {!isOpenFormModal ? getDataTable() : null}
              {isOpenFormModal ? (
                <div className='row'>
                  <div className='col-9'>
                    {getScreenHeader()}
                    {getTabInfo()}
                    {getDataTable()}
                    {/* {getFormModal()} */}
                    {config.paginationPosition == 'bottom' ? getPaginator() : null}
                  </div>
                  <div className='col-3' style={{ borderLeftStyle: 'solid', borderLeftColor: 'lightgrey' }}>
                    {getFormModal()}
                  </div>
                </div>)
                : null
              }
              {!isOpenFormModal ? (config.paginationPosition == 'bottom' ? getPaginator() : null) : null}
            </CardBody>
          </Card>
        )
      }
      if (displayViewOfForm == "sideForm" && props.sideFormLeftOrRight == "left") {
        return (
          <Card className='cardForListMargin' >
            <CardBody className='tableCardBody'>
              {!isOpenFormModal ? getScreenHeader() : null}
              {getTabInfo()}
              {!isOpenFormModal ? getDataTable() : null}

              {isOpenFormModal ? (
                <div className='row'>
                  <div className='col-3' style={{ borderRightStyle: 'solid', borderRightColor: 'lightgrey' }}>
                    {getFormModal()}
                  </div>
                  <div className='col-9'>
                    {getScreenHeader()}
                    {getTabInfo()}
                    {getDataTable()}
                    {/* {getFormModal()} */}
                    {config.paginationPosition == 'bottom' ? getPaginator() : null}
                  </div>
                </div>)
                : null
              }

              {!isOpenFormModal ? (config.paginationPosition == 'bottom' ? getPaginator() : null) : null}
            </CardBody>
          </Card>
        )
      }
    }
    if (viewType === 'grid') {
      if (displayViewOfForm == "sideForm") {
        return (
          <div>
            <Card className={' pb-2 cardForGridMargin'} >
              <CardBody className='tableCardBody'>
                {!isOpenFormModal ? getScreenHeader() : null}
                {getTabInfo()}
              </CardBody>
            </Card>
            {!isOpenFormModal ? getGridView() : null}
          </div>
        )
      }
    }
    if (viewType === 'grid' && displayViewOfForm != "sideForm") {
      return (
        <div>
          <Card
            className={' pb-2 cardForGridMargin'}>
            <CardBody className='tableCardBody pb-0'>
              {!isOpenFormModal && displayViewOfForm === 'screen' ? getScreenHeader() : displayViewOfForm === 'modal' ? getScreenHeader() : null}
              {getTabInfo()}
              {isOpenFormModal ? getFormModal() : null}
            </CardBody>
          </Card>
          {!isOpenFormModal && displayViewOfForm === 'screen' ? getGridView() : displayViewOfForm === 'modal' ? getGridView() : null}
        </div>
      )
    }
  }

  const getMobileCard = () => {
    return (
      <div>
        <Loader loader={isLoading} />
        {isOpenFormModal ? null : <div className='row mr-0 ml-0'>
          <div className='col-sm-12 px-0 pb-2'>
            <h4><span className='mobileScreenHaederView'><b >{props.type}</b></span>
              <span className='float-right'>
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Settings"
                    onClick={() => openShowHideColumnsModal()}
                  />
                </Button>
              </span>
            </h4>
          </div>
        </div>}
        {isOpenFormModal ? getMobileForm() : getGridView()}
        {!isOpenFormModal ? <PaginatorComponent
          totalRecords={totalRecordsLength}
          first={first}
          rows={rows}
          onPageChange={onPageChange}
          isWeb={false}
        /> : null}
      </div >
    )
  }

  const flattenArray = (arrayVal) => {
    let val = '';
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ');
    }
    return val;
  }
  const getMobileForm = () => {
    return (
      <div className='row'>
        <div className='col-sm-12 px-1'>
          <Card className='pb-0'>
            <CardBody className='tableCardBody pb-0'>
              {/* {getScreenHeader()} */}
              {getFormModal()}
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
  const getFormFields = () => {
    formModalRef.getFormFields()
  }
  const getFormModal = () => {

    return (
      <FormModal
        onRef={(ref) => formModalRef = ref}
        openFormModal={isOpenFormModal}
        tableRowData={tableRowData}
        allUsersData={allUsersData}
        totalRecords={totalRecordsLength}
        first={first}
        rows={rows}
        page={page}
        closeFormModal={closeFormModal}
        // closeRejectModal={closeRejectModal}
        type={customFormData?.type ? customFormData?.type : props.type}
        tablefieldsToShow={tablefieldsToShow}
        originalTableFields={originalTableFields}
        formType={formType}
        formFields={customFormData?.getFormFields ? customFormData?.getFormFields : props.formFields}
        getDataFromServer={getDataFromServer}
        editRequired={props.editRequired}
        getEditInfoKeys={props.getEditInfoKeys}
        filterExtension={props.filterExtension}
        idNotRequired={props.idNotRequired}
        apiUrl={customFormData?.formUrl ? customFormData?.formUrl : props.formUrl ? props.formUrl : apiUrl}
        role={loginRole}
        tabType={tabType}
        getDoctorPostingFields={props.getDoctorPostingFields}
        categoryNames={props.categoryNames}
        companiesList={props.companiesList}
        getSettings={getSettings}
        filterCriteria={filterCriteria}
        menuList={props.menuList}
        routeTo={props.routeTo}
        displayName={props.displayName}
        displayViewOfForm={displayViewOfForm}
        displayBreadCrumbValue={displayBreadCrumbValue}
        displayBreadCrumbField={displayBreadCrumbField}
        userStatus={userStatus}
        addSelectedRecord={props.addSelectedRecord}
        actionsTypes={actionsTypes}
        entityType={props.entityType}
        setFilterCriteriaForActivities={setFilterCriteriaForActivities}
        getColorFromOptions={getColorFromOptions}
        selectedId={selectedId}
        screenName={customFormData?.type ? customFormData?.type : props.type}
        savedFiltCriteria={savedFiltCriteria}
        assingValueToForm={props.assingValueToForm}
        replaceUpdatedData={replaceUpdatedData}
        softUpdate={props.softUpdate}
      />
    )
  }

  const getViewModal = () => {
    return (
      <ViewModal
        type={props.type}
        openViewModal={openViewModal}
        displayViewOfForm={displayViewOfForm}
        rowData={rowData}
        formFields={props.formFields}
        onRef={(ref) => (viewModalRef = ref)}
        closeViewModal={closeViewModal}
        locationId={locationId}
        drawers={drawers}
      />
    )
  }

  const getOpenShowHideColumnsModal = () => {
    return (
      <ShowHideColumnsModal
        viewType={viewType}
        isOpenShowHideColumnsModal={isOpenShowHideColumnsModal}
        closeShowHideColumnsModal={closeShowHideColumnsModal}
        tableFields={originalTableFields}
        setOriginalTableFields={setOriginalTableFields}
        type={props.type}
      />
    )
  }

  const getDeleteRowModal = () => {
    return (
      <DeleteRowModal
        openDeleteModal={openDeleteModal}
        closeDeleteModal={closeDeleteModal}
        selectActions={selectActions}
        deleteSelectedRow={deleteSelectedRow}
        confirmModalText={confirmModalText}
      />
    )
  }


  const getSharedFilesModal = () => {
    return (
      <ShareModal
        openSharedModal={openSharedModal}
        closeDeleteModal={closeDeleteModal}
        sharedFiles={sharedFiles}
        selectedRowsId={selectedRows}
        type={props.type}
        filterCriteria={filterCriteria}
        displayName={props.displayName}
        sharedFilesResponse={sharedFilesResponse}
        getDataFromServer={getDataFromServer}
      />
    )
  }


  const getMoveFolderModal = () => {
    return (
      <MoveFilesModal
        openMoveFolder={openMoveFolder}
        closeDeleteModal={closeDeleteModal}
        moveFolders={moveFolders}
        selectedRowsId={selectedRows}
        moveFoldersId={moveFoldersId}
        getDataFromServer={getDataFromServer}
      />
    )
  }


  const getFileUplodeModal = ()=>{
    return(
      <MultiFileUplode 
        openUplodModal={openUplodModal}
        closeDeleteModal={closeDeleteModal}
        MultiFolderUplode={MultiFolderUplode}
        assingValueToForm={props.assingValueToForm}
        getDataFromServer={getDataFromServer}
        showCurrentFilesInProgressBar={showCurrentFilesInProgressBar}
        setFilesToProgressBar={setFilesToProgressBar}
        updateFileStatus={updateFileStatus}
        updateFileProgress={updateFileProgress}
        title={'Upload Folder'}
      />
    )
  }

  const getPreviewModal = () => {
    return (
      <PreviewModal
        isPreviewModal={isPreviewModal}
        closePreviewModal={closePreviewModal}
        emailTemplateData={emailTemplateData}
        type={props.type}
      />
    )
  }
  const getSendEMailFields = () => {
    return ([{
      required: true,
      value: '',
      type: 'text',
      name: 'email',
      label: 'To',
      id: 'email',
      placeholder: 'Email'
    },
    {
      required: true,
      value: '',
      type: 'text',
      name: 'subject',
      label: 'Subject',
      id: 'subject',
      placeholder: 'Subject'
    },
    // {
    //   required: true,
    //   value: '',
    //   type: 'radio',
    //   name: 'type',
    //   label: 'Type',
    //   id: 'type',
    //   options:[{label:"Custom",value:"Custom"},{label:"Templates",value:"Templates"}],
    //   placeholder: 'Type'
    // },
    {
      required: true,
      value: '',
      type: 'ckeditor',
      name: 'templateText',
      label: 'Body',
      id: 'emailTemplate',
      placeholder: 'name'
    }])
  }
  const getSendEmailModal = () => {
    return (
      <SendEmailModal
        onRef={(ref) => SendEmailModalRef = ref}
        openSendEmailModal={openSendEmailModal}
        closeSendEmailModal={closeSendEmailModal}
        type={props.type}
        item={selectedRows}
        formFields={getSendEMailFields}
        parentFormFields={props.formFields()}
        apiUrl={props.routeTo}
      />
    )
  }
  const getBulkUploadModal = () => {
    return (
      <BulkUploadModal
        // onRef={(ref) => bulkUploadMoadalRef = ref}
        bulkApi={props.bulkApi}
        type={props.type}
        sample={props.sample}
        sampleFilePath={`${bulkSampleFiles[props.apiUrl]}`}
        // sampleFilePath={props.sampleFilePath}
        openBulkUploadModal={openBulkUploadModal}
        closeBulkModal={closeBulkModal}
        reload={getDataFromServer}
      />
    )
  }

  const getExportToCsvModal = () => {
    return (
      <ExportToCsvModal
        tableFields={props.getTableFields()}
        checkedFields={checkedFields}
        openExportToCsvModal={openExportToCsvModal}
        closeExportToCsvModal={closeExportToCsvModal}
        reload={getDataFromServer}
      />
    )
  }

  const getConfirmatioinModal = () => {
    return (
      <ConfirmationModal
        formType={formType}
        onRef={(ref) => (confirmRef = ref)}
        openConfirmationModal={openConfirmationModal}
        closeConfirmationModal={closeConfirmationModal}
        confirm={clickConfirm}
        text={confirmText}
      />
    )
  }

  const getSessionExpiryModal = () => {
    return (
      <SessionExpiryModal
        SOpen={sessionExpiryModal}
      />
    )
  }

  const navigateTo = () => {
    if (props.filterQueryParam?.searchId && filterQueryParam?.searchName) {
      <NavLink to={`/${props.filterQueryParam?.searchId}?searchId=${item._id}&searchName=${filterQueryParam?.searchName}`} />
    } else {
    <Navigate to={`/${props.routeTo}`} />
    }
  }

  const customChangeFieldValues = (item , tableItem) => {
    if(tableItem.field && (item?.[tableItem.parentField] || item[tableItem.field])) {
      if( tableItem.customFieldType && tableItem.customFieldType === 'memoryConversion') {
        if(item[tableItem.field]) {
          return formatBytes(item[tableItem.field])
        }
        if(item?.[tableItem.parentField]?.[tableItem.field]) {
          return formatBytes(item?.[tableItem.parentField]?.[tableItem.field])
        }
      } 
    }
    if(tableItem.customFieldType && tableItem.customFieldType === 'innerHTML') {
      return <div dangerouslySetInnerHTML={{ __html: item[tableItem.field] }} ></div>

    }
    else if(tableItem.customFieldType && tableItem.customFieldType === 'pdfLink') {
     return <a href={`${config.imgUrl}${item?.path}#page=${item.page_no}`} target="_blank">
      <span
        className='font-size-10'
        style={{ fontWeight: 'bold' }}>
          {item[tableItem.field]}
        </span>
      </a>
    }

    else if(tableItem.customFieldType && tableItem.customFieldType === 'matchedContent') {
      if(item[tableItem.field] instanceof Array && item[tableItem.field]?.length > 0) {
        
       return item[tableItem.field].map( field => {
          return <>
          <div className='d-flex justify-content-between align-items-start'>
            <div
              className='flex-grow-1 text-truncate-2-lines'
              dangerouslySetInnerHTML={{ __html: field.matchedContent }}
            ></div>
            <div className='page-number'>
              <a href={`${config.imgUrl}${field?.path}#page=${field.page_no}`} target="_blank">
                <span
                  className='font-size-9 d-flex'
                  style={{ fontWeight: 'bold' }}>
                  <small>page no :- </small>{field.page_no}
                </span>
              </a>
            </div>
          </div>
          <hr />
        </>
        
        })
      }
    }
    else if (tableItem.customFieldType && tableItem.customFieldType === 'phoneNumber') {
      return <span>
       {item[tableItem.field] ? 
       <span>{formatPhoneNumber(item[tableItem.field])}</span> : <span>{item[tableItem.field]}</span>
       }
      </span>
    }
  }

  const replaceUpdatedData = (updatedData) => {
    if(allUsersData?.length > 0) {
      let index = allUsersData.findIndex( data => data?._id === updatedData?._id);
      if(index !== -1) {
        let tempData = allUsersData;
        tempData[index] = updatedData
        setAllUsersData(tempData)
      }
    }
  }

  const softDelete = (deleteId) => {
    if(allUsersData?.length > 0) {
      let index = allUsersData.findIndex( data => data?._id === deleteId);
      if(index !== -1) {
        let tempData = allUsersData;
        tempData.splice(index,1);
        numberOfFoldersRef.current = numberOfFoldersRef.current -1;
        setAllUsersData(tempData)
      }
    }
  }

  return (
    <div >
      {width <= 576 && getMobileCard()}
      {width >= 577 && getTotalCard()}
      {isOpenShowHideColumnsModal ? getOpenShowHideColumnsModal() : null}
      {openDeleteModal ? getDeleteRowModal() : null}
      {openSharedModal ? getSharedFilesModal() : null}
      {openMoveFolder ? getMoveFolderModal() : null}
      {openUplodModal ? getFileUplodeModal():null}
      {openViewModal ? getViewModal() : null}
      {props.sample ? getBulkUploadModal() : null}
      {openExportToCsvModal ? getExportToCsvModal() : null}
      {isOpenConfirmationModal ? getConfirmatioinModal() : null}
      {sessionExpiryModal ? getSessionExpiryModal() : null}
      {isPreviewModal ? getPreviewModal() : null}
      {redirectToRoute ? navigateTo : null}
      {openSendEmailModal ? getSendEmailModal() : null}
    </div >
  );

}

const mapStateToProps = state => {
  return { articles: state.articles };
};
const List = connect(mapStateToProps)(DataTables);

export default withTranslation('common')(DataTables);
