import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Card, CardBody, Col } from "reactstrap";
import DoFileUplaod from '../../../Form/Fields/DoFileUpload';
import { Button } from "primereact/button";
import fetchMethodRequest from "../../../../config/service";
import apiCalls from "../../../../config/apiCalls";
import { useNavigate } from "react-router";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import configMessages from "../../../../config/configMessages";


export const CompareFiles = (props) => {

  const [errorMessage, setErrorMessage] = useState('');
  const [uploadedFiles , setUploadedFiles] = useState({});
  const [comparison , setComparison] = useState('');
  const [disableCompareButton , setDisableCompareButton] = useState(false)

  const { control, handleSubmit } = useForm({});
  const navigate = useNavigate();

  const onSubmit = (values) => {
    if(values['file1'] && values['file2']) {
      setDisableCompareButton(true);
      if(comparison) {
        setComparison(false);
      }
      if(errorMessage) {
        setErrorMessage('');
      }
      let requestObj = {...values,...uploadedFiles};
      fetchMethodRequest('POST', apiCalls.compareFiles, requestObj).then( res => {
        if(res?.comparison) {
          setComparison(res.comparison);    
        } else {
          showToasterMessage('error', configMessages.comparingFilesError);
          setDisableCompareButton(true);
        }
      });
    } else {
      setErrorMessage('Two Files are Required for comparision.');
    }
  }

  const handleBack = () => {
    navigate(-1);
  };

  const onSuccess = (resObj) => {
    if(resObj) {
      setUploadedFiles( prevData => ({...prevData,...resObj}));
    }
    if(disableCompareButton) {
      setDisableCompareButton(false);
    }
  }

  const getFormModal = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Col md={12} lg={12}>
        <Card>
          <CardBody className="p-3">
            <div className="card__title">
              <h5 className="bold-text">Compare Files</h5>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='row gy-3'>
                <div className='col-sm-12 col-md-5'>
                  <div className="form__form-group">
                    <span className="form__form-group-label">File 1</span>
                    <div className="form__form-group-field">
                      <Controller
                        name={'file1'}
                        control={control}
                        render={({ field, fieldState }) => (
                          <DoFileUplaod
                            field={field}
                            url={"uploads?uploadPath=comparefiles"}
                            type={'comparefiles'}
                            acceptType=".pdf,.docx"
                            showPreview={false}
                            userClickUpload={true}
                            maxFileSize={5000000}
                            isMultiple={false}
                            modeType={'basic'}
                            autoSubmit={true}
                            profile={true}
                            onSuccess={onSuccess}
                          />)}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-5'>
                  <div className="form__form-group">
                    <span className="form__form-group-label">File 2</span>
                    <div className="form__form-group-field">
                      <Controller
                        name={'file2'}
                        control={control}
                        render={({ field, fieldState }) => (
                          <DoFileUplaod
                            field={field}
                            url={"uploads?uploadPath=comparefiles"}
                            type={'comparefiles'}
                            acceptType=".pdf,.docx"
                            showPreview={false}
                            userClickUpload={true}
                            maxFileSize={5000000}
                            isMultiple={false}
                            modeType={'basic'}
                            autoSubmit={true}
                            profile={true}
                            onSuccess={onSuccess}
                          />)}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-2'>
                  <div className='d-flex mt-3 justify-content-end align-items-center flex-wrap gap-2'>
                    <Button type="button" size='sm' color='primary' label='Back' className='me-3' onClick={handleBack}/>
                    <Button type='submit' size='sm' color='primary' label='Compare' disabled={disableCompareButton} />
                </div>
                </div>
              </div>
             { errorMessage && Object.keys(uploadedFiles).length !== 2 && <div className="mt-3">
                <small className="text-danger">{errorMessage}</small>
              </div>}
            </form>
          </CardBody>
        </Card>
      </Col >
      </div>
    );
  }

  return <>
    {getFormModal()}
    {comparison &&
      <div className="d-flex justify-content-center align-items-cemter">
        <Col md={12} lg={12}>
          <Card>
            <CardBody className="d-flex justify-content-center align-items-cemter">
            <div className="compare-table-wrapper"><div dangerouslySetInnerHTML={{ __html: comparison }} /></div>
              </CardBody>
            </Card>
        </Col>
      </div>
     }
  </>
}